import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

const IsAuthenticated = () => {
  const userData = useSelector((state) => state.userData.user);

  const isAuthenticated = userData?.accessToken;

  return isAuthenticated ? <Navigate to="/calender" /> : <Outlet />;
};

export default IsAuthenticated;
