import { CheckIcon, Plus } from "lucide-react";
import React, { useState } from "react";
import ButtonLoader from "../Common/ButtonLoader";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { checkAuthAndRedirect } from "../Common/RefreshToken";

export default function TopActions({
  staffs,
  createStaff,
  setCreateStaff,
  selectedStaffId,
  setSelectedStaffId,
  userCreateRequest,
  setUserCreateRequest,
  staffProfileCreateRequest,
  setStaffProfileCreateRequest,
  setActiveView,
}) {
  const [loading, setLoading] = useState(false);
  const loggedUserData = useSelector((state) => state.userData.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const validateStaff = () => {
    let isValid = true;

    if (
      !userCreateRequest.firstName ||
      userCreateRequest.firstName.trim() === ""
    ) {
      toast.error("First name is required.");
      isValid = false;
      return;
    }

    if (
      !userCreateRequest.lastName ||
      userCreateRequest.lastName.trim() === ""
    ) {
      toast.error("Last name is required.");
      isValid = false;
      return;
    }

    if (!userCreateRequest.email || userCreateRequest.email.trim() === "") {
      toast.error("Birthdate is required.");
      isValid = false;
      return isValid;
    }

    if (
      !userCreateRequest.phoneNumber ||
      userCreateRequest.phoneNumber.trim() === ""
    ) {
      toast.error("Country Code is required.");
      isValid = false;
      return;
    }

    return isValid;
  };

  const handleCreateStaff = async (event) => {
    event.preventDefault();
    setLoading(true);
    const token = await checkAuthAndRedirect(navigate, dispatch, loggedUserData);

    if (!validateStaff()) {
      setLoading(false);
      return;
    }

    const url = `${process.env.REACT_APP_API_URL}/api/v1/staff`;
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        userCreateRequest,
        staffProfileCreateRequest,
      }),
    };

    try {
      const response = await fetch(url, options);
      const data = await response.json();
      if (response.ok) {
        toast.success("Staff Created Successfully!");
        setSelectedStaffId(data.id);
        setCreateStaff(false);
        setLoading(false);
      } else {
        toast.error(data.detail || "Please validate the Fields");
        setLoading(false);
      }
    } catch (error) {
      console.error("Catch error:", error);
      toast.error("Internal Server Error");
      setLoading(false);
    }
  };

  const handleUpdateStaff = async (event) => {
    event.preventDefault();
    setLoading(true);
    const token = await checkAuthAndRedirect(navigate, dispatch, loggedUserData);
  
    if (!validateStaff()) {
      setLoading(false);
      return;
    }
  
    // Append the id to userUpdateRequest
    const updatedUserRequest = {
      ...userCreateRequest,
      id: selectedStaffId,
    };
  
    const url = `${process.env.REACT_APP_API_URL}/api/v1/staff`;
    const options = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        userUpdateRequest: updatedUserRequest,
        staffProfileUpdateRequest: staffProfileCreateRequest,
      }),
    };
  
    try {
      const response = await fetch(url, options);
      if (response.ok) {
        toast.success("Staff Updated Successfully!");
        setLoading(false);
      } else {
        const errorData = await response.json();
        toast.error(errorData.detail || "Please validate the Fields");
        setLoading(false);
      }
    } catch (error) {
      console.error("Catch error:", error);
      toast.error("Internal Server Error");
      setLoading(false);
    }
  };
  

  const handleOnCreateStaffClick = () => {
    setCreateStaff(true);
    setActiveView("details");
    setSelectedStaffId(null);
    setUserCreateRequest({
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
    });
    setStaffProfileCreateRequest({
      staffIsAlsoManager: false,
      acceptOnlineBooking: false,
      showCalendarInPortal: false,
      showInStaffList: false,
    });
  };

  return (
    <div className="flex justify-between items-center mb-6">
      <div className="flex items-center">
        <span className="text-sm font-normal bg-secondary text-primary rounded px-3 py-1 mr-2">
          {staffs?.length}
        </span>
        <h2 className="font-bold text-xl">Staff</h2>
      </div>
      <div className="flex gap-3">
        {createStaff ? (
          <button
            onClick={handleCreateStaff}
            disabled={loading}
            className="flex items-center gap-2 justify-center font-medium text-white bg-primary md:px-5 px-4 md:py-3 py-2 rounded-lg"
          >
            {loading ? (
              <div className="w-full flex justify-center">
                <ButtonLoader />
              </div>
            ) : (
              <>
                <CheckIcon size={16} color="#ffff" />
                <h2>Save</h2>
              </>
            )}
          </button>
        ) : (
          ""
        )}

        {selectedStaffId ? (
          <button
            onClick={handleUpdateStaff}
            className="flex items-center gap-2 justify-center font-medium text-white bg-primary md:px-5 px-4 md:py-3 py-2 rounded-lg"
          >
            {loading ? (
              <div className="w-full flex justify-center">
                <ButtonLoader />
              </div>
            ) : (
              <>
                <CheckIcon size={16} color="#ffff" />
                <h2>Save</h2>
              </>
            )}
          </button>
        ) : (
          ""
        )}

        <button
          onClick={handleOnCreateStaffClick}
          className="flex items-center space-x-2 font-medium text-primary hover:bg-primary hover:text-white bg-secondary md:px-4 px-2 py-2 rounded-lg"
        >
          <Plus size={22} />
          <h2 className="hidden md:block">New Staff</h2>
        </button>
      </div>
    </div>
  );
}
