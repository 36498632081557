import React, { useState } from "react";
import { ChevronDown, ChevronUp } from "lucide-react";

function ServiceSidebar({
  services,
  onSelectService,
  setCreateService,
}) {
  const [expandedCategories, setExpandedCategories] = useState(
    services.map((category) => category?.id)
  );

  const toggleCategory = (categoryId) => {
    setExpandedCategories((prev) =>
      prev.includes(categoryId)
        ? prev.filter((id) => id !== categoryId)
        : [...prev, categoryId]
    );
  };

  const handleOnClick = (id) => {
    onSelectService(id);
    setCreateService(false);
  };

  return (
    <div className="w-full mb-2">
      {services.map((category) => (
        <div key={category.id}>
          <div
            className="flex items-center justify-between w-full p-3 bg-[#F0F5F7] cursor-pointer border-b border-[#ddecf3]"
            onClick={() => toggleCategory(category.id)}
          >
            <span className="font-medium text-primary ms-3">
              {category.name}
            </span>
            {expandedCategories.includes(category.id) ? (
              <ChevronUp className="h-4 w-4" />
            ) : (
              <ChevronDown className="h-4 w-4" />
            )}
          </div>
          {expandedCategories.includes(category.id) && (
            <div>
              {category?.services.map((service, index) => (
                <div
                  key={index}
                  className="p-3 border-b border-[#ddecf3] cursor-pointer"
                  onClick={() => handleOnClick(service?.id)}
                >
                  <div className="font-medium ms-3">{service?.name}</div>
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

export default ServiceSidebar;
