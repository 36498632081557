import React from "react";
import Allergies from "./Forms/Allergies";
import Consumption from "./Forms/Consumption";
import HeartOrBlood from "./Forms/HeartOrBlood";
import Female from "./Forms/Female";
import Other from "./Forms/Other";

function FormsTab({
  allergies,
  setAllergies,
  consumptions,
  setConsumptions,
  heartOrBloodIssues,
  setHeartOrBloodIssues,
  femaleForm,
  setFemaleForm,
  formOther,
  setFormOther,
}) {
  return (
    <div className="pb-5 px-5 grid grid-cols-1 md:grid-cols-1 gap-5">
      <Allergies allergies={allergies} setAllergies={setAllergies} />
      <Consumption
        consumptions={consumptions}
        setConsumptions={setConsumptions}
      />
      <HeartOrBlood
        heartOrBloodIssues={heartOrBloodIssues}
        setHeartOrBloodIssues={setHeartOrBloodIssues}
      />
      <Female femaleForm={femaleForm} setFemaleForm={setFemaleForm} />
      <Other formOther={formOther} setFormOther={setFormOther} />
    </div>
  );
}

export default FormsTab;
