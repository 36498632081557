"use client";

import React from "react";
import { ChevronRight, X } from "lucide-react";
import { ArrowRightIcon } from "../../utils/Icons";

export default function AddRecalModal({ isOpen, onClose }) {
  if (!isOpen) return null;

  return (
    <div className="fixed z-50 inset-0 bg-black bg-opacity-50 h-full flex items-center justify-end">
      <div className="bg-white rounded-lg p-6 w-full max-w-md h-full overflow-y-auto">
        <div className="flex justify-between items-center mb-4 ">
          <button className="cursor-pointer">
            <ArrowRightIcon />
          </button>
          <h2 className="text-xl text-primary">Add Recall</h2>
          <button onClick={onClose} className="text-gray-600">
            <X />
          </button>
        </div>
        <div className="flex flex-col justify-between h-[90vh]  border-t border-[#DDECF3]">
          <div className="flex-1">
            <h1 className="mt-8 mb-4">
              Choose when to send a recall messageto Gerinzo?
            </h1>
            <div>
              <div className=" w-full  group mb-3">
                <select
                  id="type"
                  defaultValue="Gerinzo Cibuku"
                  className="px-3 block py-4 w-full text-sm text-gray-900 bg-transparent border border-[#ddecf3] rounded-md"
                >
                  <option value="">In 1 month</option>
                  <option value="">Nurse</option>
                </select>
              </div>
              <div className=" w-full  mb-3">
                <input
                  type="date"
                  id="date"
                  defaultValue="04/02/2024"
                  className="ps-3 block py-4  w-full text-sm text-gray-900 bg-transparent border border-[#ddecf3] appearance-none rounded-lg"
                />
              </div>
              <div className=" w-full  mb-3">
                <input
                  type="time"
                  id="time"
                  defaultValue="09:00"
                  className="ps-3 block py-4  w-full text-sm text-gray-900 bg-transparent border border-[#ddecf3] appearance-none rounded-lg"
                />
              </div>
            </div>
          </div>
          <div className=" border w-full py-4 bg-primary text-white border-none hover:bg-[#ddecf3] hover:text-primary rounded-md">
            <button className="flex items-center justify-between px-4  w-full">
              <h2>Add Recall</h2>
              <ChevronRight className="ml-2" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
