import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

export default function AuthRedirect() {
  const userData = useSelector((state) => state.userData.user);

  const isAuthenticated = userData?.accessToken;

  return isAuthenticated ? <Outlet /> : <Navigate to="/" />;
}
