import React from "react";
import Layout from "../Components/Common/Layout";
import StaffList from "../Components/Staff/StaffList";

function Staff() {
  return (
    <Layout>
      <StaffList />
    </Layout>
  );
}

export default Staff;
