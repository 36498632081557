import React, { useState } from "react";
import { Plus } from "lucide-react";
import StaffDetails from "./StaffDetails";
import { UserIcon } from "../../utils/Icons";
import TopActions from "./TopActions";
import LeftSide from "./LeftSide";

function StaffList() {
  const [staffs, setStaffs] = useState([]);
  const [selectedStaffId, setSelectedStaffId] = useState(null);
  const [activeView, setActiveView] = useState("details");
  const [createStaff, setCreateStaff] = useState(false);

  const [userCreateRequest, setUserCreateRequest] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
  });

  const [staffProfileCreateRequest, setStaffProfileCreateRequest] = useState({
    staffIsAlsoManager: false,
    acceptOnlineBooking: false,
    showCalendarInPortal: false,
    showInStaffList: false,
    servicesAssigned: [],
  });

  const handleOnCreateStaffClick = () => {
    setCreateStaff(true);
    setSelectedStaffId(null);
    setUserCreateRequest({
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
    });
    setStaffProfileCreateRequest({
      staffIsAlsoManager: false,
      acceptOnlineBooking: false,
      showCalendarInPortal: false,
      showInStaffList: false,
    });
  };

  const handleOnBack = () => {
    setSelectedStaffId(null);
    setCreateStaff(null);
  };

  return (
    <>
      <div className="w-full max-w-6xl mx-auto">
        <TopActions
          staffs={staffs}
          createStaff={createStaff}
          setCreateStaff={setCreateStaff}
          selectedStaffId={selectedStaffId}
          setSelectedStaffId={setSelectedStaffId}
          userCreateRequest={userCreateRequest}
          setUserCreateRequest={setUserCreateRequest}
          staffProfileCreateRequest={staffProfileCreateRequest}
          setStaffProfileCreateRequest={setStaffProfileCreateRequest}
          setActiveView={setActiveView}
        />

        <div className="bg-white rounded-xl border-[1px] border-[#ddecf3] shadow-lg h-[75vh] flex flex-col">
          <div className="flex flex-col lg:flex-row h-full">
            <LeftSide
              staffs={staffs}
              selectedStaffId={selectedStaffId}
              setSelectedStaffId={setSelectedStaffId}
              setStaffs={setStaffs}
              setCreateStaff={setCreateStaff}
              createStaff={createStaff}
              setActiveView={setActiveView}
            />
            <div className="w-full overflow-y-auto scroll-smooth rounded-r-xl">
              {createStaff || selectedStaffId ? (
                <StaffDetails
                  selectedStaff={createStaff ? null : selectedStaffId}
                  activeView={activeView}
                  setActiveView={setActiveView}
                  onBack={handleOnBack}
                  userCreateRequest={userCreateRequest}
                  setUserCreateRequest={setUserCreateRequest}
                  staffProfileCreateRequest={staffProfileCreateRequest}
                  setStaffProfileCreateRequest={setStaffProfileCreateRequest}
                />
              ) : (
                <div className="hidden md:px-0 px-4 h-full w-full lg:w-2/3-50  rounded-r-xl md:flex justify-center items-center">
                  <div className="flex flex-col items-center text-center mb-6">
                    <UserIcon className="h-12 w-12 text-primary " />
                    <h2 className="md:text-2xl text-xl font-semibold  text-primary my-3">
                      Staff
                    </h2>
                    <p className="text-sm text-gray-600 md:w-2/3">
                      All Staff to this business account are displayed in the
                      left sidebar. More details about a particular team member
                      are presented on the right.
                    </p>
                    <button
                      onClick={handleOnCreateStaffClick}
                      className="font-medium mt-7 bg-[#ddecf3] hover:bg-primary hover:text-white text-primary flex items-center px-6 py-3 rounded-lg"
                    >
                      <Plus size={22} className="me-3" /> New Staff
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default StaffList;
