import React from "react";
import Layout from "../Components/Common/Layout";
import ServiceList from "../Components/ServicesPage/ServiceList";

function Service() {
  return (
    <Layout>
      <ServiceList />
    </Layout>
  );
}

export default Service;
