import React, { useState, useRef } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { Calendar1, Plus, CalendarX } from "lucide-react";
import NewBooking from "./NewBooking";
import UnavaliableTime from "./UnavaliableTime";

const hardcodedEvents = [
  {
    title: "Event 1",
    start: "2024-10-13T09:00:00",
    end: "2024-10-13T11:30:00",
    color: "#8DAA3B",
  },
  {
    title: "Event test",
    start: "2024-10-11T09:00:00",
    end: "2024-10-11T11:00:00",
    color: "#B5B5B5",
  },
  {
    title: "Event 1",
    start: "2024-10-09T09:00:00",
    end: "2024-10-09T10:30:00",
    color: "#6F8FD8",
  },
  {
    title: "Event 3",
    start: "2024-10-19T13:00:00",
    end: "2024-10-19T14:30:00",
    color: "#6F8FD8",
  },
  {
    title: "Event 4",
    start: "2024-10-08T12:00:00",
    end: "2024-10-08T14:30:00",
    color: "#B5B5B5",
  },
  {
    title: "Event 4",
    start: "2024-10-12T13:00:00",
    end: "2024-10-12T14:00:00",
    color: "#B5B5B5",
  },
];

function CalenderTable() {
  const [events] = useState(hardcodedEvents);
  const calendarRef = useRef(null);
  const [isBookingModalOpen, setBookingModalOpen] = useState(false);
  const [isUnavaliablegModalOpen, setUnavaliableModalOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const handleTodayClick = () => {
    const calendarApi = calendarRef.current.getApi();
    calendarApi.today();
  };

  const handleOptionClick = (option) => {
    if (option === "New Booking") {
      setBookingModalOpen(true);
    }
    if (option === "Unavailable Time") {
      setUnavaliableModalOpen(true);
    }
    setDropdownOpen(false);
  };

  const handleCloseBookingModal = () => {
    setBookingModalOpen(false);
  };

  const handleCloseUnavaliableModal = () => {
    setUnavaliableModalOpen(false);
  };

  const toggleDropdown = () => {
    setDropdownOpen((prev) => !prev);
  };

  return (
    <div className="w-full max-w-6xl mx-auto p-4">
      <div className="mb-4 relative">
        <button
          className="mb-3 bg-secondary text-primary flex items-center px-3 py-2 rounded-lg"
          onClick={toggleDropdown}
        >
          <Plus size={22} className="me-3" /> Add
        </button>
        {dropdownOpen && (
          <div className="absolute top-full left-0 mt-2 bg-white shadow-lg rounded-lg w-60 z-10 border py-2">
            <div
              className="px-4 py-2 cursor-pointer hover:bg-gray-100 flex items-center space-x-2"
              onClick={() => handleOptionClick("New Booking")}
            >
              <div className="bg-primary text-white p-2 rounded-lg">
                <Calendar1 size={18} />
              </div>
              <span className="whitespace-nowrap">New Booking</span>
            </div>
            <div
              className="px-4 py-2 cursor-pointer hover:bg-gray-100 flex items-center space-x-2"
              onClick={() => handleOptionClick("Unavailable Time")}
            >
              <div className="bg-secondary text-primary p-2 rounded-lg">
                <CalendarX size={18} />
              </div>
              <span className="whitespace-nowrap">Unavailable Time</span>
            </div>
          </div>
        )}
      </div>
      <div className="bg-white p-4 md:p-6 rounded-xl shadow-lg border">
        <FullCalendar
          ref={calendarRef}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          initialView={window.innerWidth < 640 ? "timeGridDay" : "timeGridWeek"}
          initialDate={new Date("2024-10-13")}
          headerToolbar={{
            left: "prev,next title",
            right:
              "today,border,timeGridDay,timeGridWeek,timeGridFourDay,dayGridMonth",
          }}
          views={{
            timeGridFourDay: {
              type: "timeGrid",
              duration: { days: 4 },
              buttonText: "Work Week",
            },
          }}
          customButtons={{
            today: {
              text: "Today",
              click: handleTodayClick,
            },
          }}
          slotMinTime="09:00:00"
          slotMaxTime="16:00:00"
          slotDuration="00:30:00"
          height="auto"
          expandRows={true}
          events={events}
          nowIndicator={true}
          dayHeaderContent={(date) => {
            return (
              <div style={{ textAlign: "start", paddingLeft: "8px" }}>
                <div
                  style={{
                    fontWeight: "normal",
                    fontSize: "16px",
                    color: "#00000050",
                  }}
                >
                  {new Intl.DateTimeFormat("en-US", {
                    weekday: "short",
                  }).format(date.date)}
                </div>
                <div style={{ fontSize: "16px", fontWeight: "bold" }}>
                  {new Intl.DateTimeFormat("en-US", { day: "2-digit" }).format(
                    date.date
                  )}
                </div>
              </div>
            );
          }}
          allDaySlot={false}
          firstDay={1}
          slotEventOverlap={false}
          slotLabelFormat={{
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          }}
          eventTimeFormat={{
            hour: "2-digit",
            minute: "2-digit",
            meridiem: false,
            hour12: true,
          }}
          windowResizeDelay={100}
          buttonText={{
            today: "Today",
            month: "Month",
            week: "Week",
            day: "Day",
          }}
          themeSystem="standard"
        />
      </div>
      <NewBooking
        isOpen={isBookingModalOpen}
        onClose={handleCloseBookingModal}
      />
      <UnavaliableTime
        isOpen={isUnavaliablegModalOpen}
        onClose={handleCloseUnavaliableModal}
      />
    </div>
  );
}

export default CalenderTable;
