import React from "react";

export default function Tabs({
  selectedCustomer,
  activeView,
  setActiveView,
  setIsDeleteDialogOpen,
}) {
  return (
    <div className="bg-[#ddecf3] w-full border-b border-[#ddecf3] md:rounded-tr-xl sticky top-0 z-20">
      <div className="px-5 py-3 grid grid-cols-3 gap-2 md:flex md:items-center md:space-x-6">
        <button
          className={`text-md rounded-lg ${
            activeView === "details"
              ? "text-primary font-bold"
              : "text-gray-600"
          }`}
          onClick={() => setActiveView("details")}
        >
          Customer
        </button>
        {selectedCustomer ? (
          <button
            className={`text-md rounded-lg ${
              activeView === "settings"
                ? "text-primary font-bold"
                : "text-gray-600"
            }`}
            onClick={() => setActiveView("settings")}
          >
            Booking
          </button>
        ) : (
          ""
        )}
        {selectedCustomer ? (
          <button
            className={`text-md rounded-lg ${
              activeView === "recalls"
                ? "text-primary font-bold"
                : "text-gray-600"
            }`}
            onClick={() => setActiveView("recalls")}
          >
            Recalls
          </button>
        ) : (
          ""
        )}

        <button
          className={`text-md rounded-lg ${
            activeView === "forms" ? "text-primary font-bold" : "text-gray-600"
          }`}
          onClick={() => setActiveView("forms")}
        >
          Forms
        </button>
        {selectedCustomer ? (
          <button
            className={`text-md rounded-lg ${
              activeView === "audit"
                ? "text-primary font-bold"
                : "text-gray-600"
            }`}
            onClick={() => setActiveView("audit")}
          >
            Audit
          </button>
        ) : (
          ""
        )}
        {selectedCustomer ? (
          <button
            className={`text-md rounded-lg ${
              activeView === "delete"
                ? "text-primary font-bold"
                : "text-gray-600"
            }`}
            onClick={() => setIsDeleteDialogOpen(true)}
          >
            Delete
          </button>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
