import { combineReducers } from "redux";
import { userReducer } from "./userReducer";
import { countryReducer } from "./countryReducer";
import { languageReducer } from "./languageReducer";
import { categoryReducer } from "./categoryReducer";

const reducers = combineReducers({
  userData: userReducer,
  countryData: countryReducer,
  languageData: languageReducer,
  categoryData: categoryReducer,
});

export default reducers;
