import React from "react";
import FieldLabel from "../../Common/FieldLabel";
import { CommentsIcon } from "../../../utils/Icons";

export default function Female({ femaleForm, setFemaleForm }) {
  const handleFemaleFormChange = (field, value) => {
    setFemaleForm((prevForm) => ({
      ...prevForm,
      [field]: value,
    }));
  };

  const labels = {
    pregnant: "Is she pregnant?",
    problemsDuringMenstrualCycle:
      "Does she have any problems during menstrual cycle?",
    takesOralContraceptives: "Is she taking any oral contraceptives?",
  };

  return (
    <div>
      <FieldLabel name={"Female Section"} />
      {Object.keys(labels).map((field) => (
        <div
          key={field}
          className="w-full border-[#ddecf3] border rounded-lg mb-2"
        >
          <div className="">
            <div
              className={`md:flex md:items-center md:justify-between ${
                femaleForm[field] !== null && femaleForm[field] !== ""
                  ? "border-b border-[#ddecf3]"
                  : ""
              }`}
            >
              <div className="py-4 px-4 md:border-0 border-b border-[#ddecf3]">
                <h2>{labels[field]}</h2>
              </div>
              <div className="flex items-center">
                <div
                  className={`font-medium border-[#ddecf3] py-4 px-7 text-primary flex items-center justify-center ${
                    femaleForm[field] !== "" && femaleForm[field] !== null
                      ? "border-r-[1px] bg-[#e8f2f7]"
                      : ""
                  }`}
                >
                  <button onClick={() => handleFemaleFormChange(field, " ")}>
                    Yes
                  </button>
                </div>
                <div
                  className={`font-medium py-4 px-7 flex items-center justify-center ${
                    femaleForm[field] === "" || femaleForm[field] === null
                      ? "border-r-[1px] border-[#ddecf3] bg-[#e8f2f7]"
                      : ""
                  }`}
                >
                  <button onClick={() => handleFemaleFormChange(field, "")}>
                    No
                  </button>
                </div>
                {femaleForm[field] !== "" && femaleForm[field] !== null && (
                  <div className="font-medium border-[#ddecf3] py-4 px-7 flex items-center justify-center">
                    <CommentsIcon color="#1882B1" />
                  </div>
                )}
              </div>
            </div>

            {femaleForm[field] !== "" && femaleForm[field] !== null && (
              <div className="py-4 px-4">
                <textarea
                  value={
                    femaleForm[field]?.trim() === "" ? "" : femaleForm[field]
                  }
                  onChange={(e) =>
                    handleFemaleFormChange(field, e.target.value.trimStart())
                  }
                  className="w-full border border-gray-300 rounded-md p-2"
                  placeholder={`Provide details for ${labels[
                    field
                  ].toLowerCase()}`}
                />
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}
