"use client";

import React from "react";
import { ChevronRight, X } from "lucide-react";
import { ArrowRightIcon } from "../../utils/Icons";

export default function AddNote({ isOpen, onClose }) {
  if (!isOpen) return null;

  return (
    <div className="fixed z-50 inset-0 bg-black bg-opacity-50 h-full flex items-center justify-end">
      <div className="bg-white rounded-lg p-6 w-full max-w-md h-full overflow-y-auto relative z-[1000]">
        <div className="flex justify-between items-center mb-4">
          <button className="cursor-pointer">
            <ArrowRightIcon />
          </button>
          <h2 className="text-xl text-primary">Note</h2>
          <button onClick={onClose} className="text-gray-600">
            <X />
          </button>
        </div>
        <div className="flex flex-col justify-between h-[90vh]">
          <div className="flex-1">.......</div>
          <div className=" border w-full py-4 bg-primary text-white border-none hover:bg-[#ddecf3] hover:text-primary  rounded-md">
            <button className="flex items-center justify-between px-4  w-full">
              <h2>Save</h2>
              <ChevronRight className="ml-2" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
