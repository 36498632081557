import React, { useState } from "react";
import {
  Calendar,
  Clock,
  UserCircle,
  ShoppingBag,
  Sparkles,
  BarChart2,
  CreditCard,
  Tag,
  LogOut,
  IdCard,
} from "lucide-react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import ButtonLoader from "../Common/ButtonLoader";
import { setUser } from "../../redux/actions/userActions";
import { setCountry } from "../../redux/actions/countryAction";
import { setCategory } from "../../redux/actions/categoryAction";
import { setLanguage } from "../../redux/actions/languageAction";

const topMenuItems = [
  { icon: Calendar, label: "Calendar", navigation: "/calender" },
  { icon: Clock, label: "Bookings", navigation: "/booking", badge: "3" },
  { icon: UserCircle, label: "Customers", navigation: "/customer" },
  { icon: IdCard, label: "Staff", navigation: "/staff" },
  { icon: ShoppingBag, label: "Services", navigation: "/service" },
];

const bottomMenuItems = [
  { icon: Sparkles, label: "Marketing" },
  { icon: BarChart2, label: "Statistics" },
  { icon: CreditCard, label: "Billing" },
  { icon: Tag, label: "Subscriptions" },
];

export default function Sidebar({ isOpen }) {
  const [hovered, setHovered] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleMouseEnter = () => setHovered(true);
  const handleMouseLeave = () => setHovered(false);
  const isSidebarOpen = isOpen || hovered;
  const token = useSelector((state) => state.userData.user.accessToken);


  const handleLogout = async () => {
    setLoading(true);

    const url = `${process.env.REACT_APP_API_URL}/api/v1/authentications/logout`;
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${token}`,
      },
      credentials: "include",
      redirect: "follow",
    };
    try {
      const response = await fetch(url, requestOptions);

      if (response.ok) {
        dispatch(setUser({}));
        dispatch(setCountry([]));
        dispatch(setCategory([]));
        dispatch(setLanguage([]));
        toast.success("Logged out successfully!");
        navigate("/");
      }
    } catch (error) {
      console.error("Catch error:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={`fixed left-0 top-[57px] bottom-0 z-40 border-r flex flex-col bg-white shadow-lg ${
        isSidebarOpen ? "w-56" : "md:w-[3.5%] w-0"
      }`}
    >
      <nav className="flex-1 overflow-y-auto">
        <ul className="py-4">
          {topMenuItems.map((item, index) => (
            <li key={index} className="px-4 py-2">
              <Link
                to={item?.navigation}
                className="flex items-center space-x-2 text-gray-700 hover:text-primary"
              >
                <item.icon className="h-5 w-5 text-primary" />
                {isSidebarOpen && <span>{item.label}</span>}
                {item.badge && isSidebarOpen && (
                  <span className="ml-auto bg-secondary text-primary text-xs font-semibold px-2 py-1 rounded-lg">
                    {item.badge}
                  </span>
                )}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
      <ul className="mb-0 py-4">
        {bottomMenuItems.map((item, index) => (
          <li key={index} className="px-4 py-2">
            <Link
              to="/"
              className="flex items-center space-x-2 text-gray-700 hover:text-primary"
            >
              <item.icon className="h-5 w-5 text-primary" />
              {isSidebarOpen && <span>{item.label}</span>}
            </Link>
          </li>
        ))}
      </ul>
      <div className="md:border-t py-4 px-4">
        <Link
          onClick={handleLogout}
          disabled={loading}
          className={`flex items-center space-x-2 text-red-500 hover:text-red-700 ${
            loading && "cursor-not-allowed opacity-50"
          }`}
        >
          {loading ? (
            <ButtonLoader circle="text-red-500 fill-red-500" />
          ) : (
            <>
              <LogOut className="h-5 w-5" />
              {isSidebarOpen && <span>Log Out</span>}
            </>
          )}
        </Link>
      </div>
    </div>
  );
}
