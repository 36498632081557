import React from "react";
import { GreaterIcon, UserIcon } from "../../utils/Icons";
import { Plus } from "lucide-react";

function RecallTab({ setModalOpen }) {
  const handleAddClick = () => setModalOpen(true);

  return (
    <div className="rounded-r-xl flex h-full w-full justify-center items-center">
      <div className="flex flex-col items-center text-center">
        <UserIcon className="h-12 w-12" />
        <h2 className="md:text-2xl font-semibold  text-primary my-3">
          Recall Gerinzo
        </h2>
        <p className="text-sm text-gray-600">
          Get more returning customers by sending them recall SMS and emails.
          This <br /> reminds and encourages your clients to book your services
          again.
        </p>
        <button
          onClick={handleAddClick}
          className="font-medium mb-3 bg-secondary text-primary mt-7 flex items-center px-6 py-3 rounded-lg"
        >
          <Plus size={22} className="me-3" /> Add New Recall
        </button>
        <div className="flex items-center gap-3 mt-2">
          <h2 className="text-black">Recalls Settings</h2>
          <GreaterIcon />
        </div>
      </div>
    </div>
  );
}

export default RecallTab;
