import React, { useState } from "react";
import { Plus } from "lucide-react";
import CustomerDetails from "./CustomerDetails";
import { UserIcon } from "../../utils/Icons";
import TopActions from "./TopActions";
import LeftSide from "./LeftSide";

function CustomerList() {
  const [customers, setCustomers] = useState([]);
  const [customersTotal, setCustomersTotal] = useState([]);
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);
  const [createCustomer, setCreateCustomer] = useState(false);
  const [activeView, setActiveView] = useState("details");

  //Date States:
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");

  //Customer States:
  const [profile, setProfile] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    birthdate: "",
    gender: 0,
    occupation: "",
    notes: "",
  });

  const [address, setAddress] = useState({
    addressLine: "",
    zip: "",
    city: "",
    countryCode: "",
  });

  const [allergies, setAllergies] = useState({
    allergicMedications: "",
    allergyFromAntibiotic: "",
    allergyFromAnythingElse: "",
    allergyFromLocalAnesthesia: "",
    allergyFromMetals: "",
  });

  const [consumptions, setConsumptions] = useState({
    alcohoolConsumption: "",
    drugTaking: "",
    tabacooConsumption: "",
  });

  const [heartOrBloodIssues, setHeartOrBloodIssues] = useState({
    bloodDisease: "",
    bloodTransfusion: "",
    congenitalHeartDisease: "",
    coronaryInsufficiency: "",
    heartSurgeryIntervention: "",
    majorHaemorrhageAfterAnyIntervention: "",
    highBlodPressure: "",
    lowBloodPressure: "",
    myocardialInfarction: "",
    peacemakerIntoHeart: "",
  });

  const [femaleForm, setFemaleForm] = useState({
    pregnant: "",
    problemsDuringMenstrualCycle: "",
    takesOralContraceptives: "",
  });

  const [formOther, setFormOther] = useState({
    arthritis: "",
    badConditionOfGingiva: "",
    bronchialAsthma: "",
    chemotherapy: "",
    chronicBronchitis: "",
    currentActiveMedication: "",
    diabetes: "",
    epilepsy: "",
    hyperThyroidism: "",
    hypoThyroidism: "",
    liverDiseases: "",
    mentalDisease: "",
    osteoporosis: "",
    peridicMedication: "",
    radiotherapy: "",
    regularExposureToXRays: "",
    rheumaticFever: "",
    ssTs: "",
    stomachUlcer: "",
    suffersFromDisease: "",
    systemicDisease: "",
    tuberculosis: "",
    underMedicalSupervision: "",
  });

  const [statistics, setStatistics] = useState({
    customerLiftime: "",
    rating: "",
    totalBooked: "",
    totalCancelled: "",
    totalMissed: "",
    totalSpend: "",
  });

  const handleOnCreateCustomerClick = () => {
    setCreateCustomer(true);
    setSelectedCustomerId(null);
    setActiveView("details")
    setDay("");
    setMonth("");
    setYear("");
    setProfile({
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      birthdate: "",
      gender: 0,
      occupation: "",
      notes: "",
    });
    setAddress({
      addressLine: "",
      zip: "",
      city: "",
      countryCode: "",
    });
    setAllergies({
      allergicMedications: "",
      allergyFromAntibiotic: "",
      allergyFromAnythingElse: "",
      allergyFromLocalAnesthesia: "",
      allergyFromMetals: "",
    });
    setConsumptions({
      alcohoolConsumption: "",
      drugTaking: "",
      tabacooConsumption: "",
    });
    setHeartOrBloodIssues({
      bloodDisease: "",
      bloodTransfusion: "",
      congenitalHeartDisease: "",
      coronaryInsufficiency: "",
      heartSurgeryIntervention: "",
      majorHaemorrhageAfterAnyIntervention: "",
      highBlodPressure: "",
      lowBloodPressure: "",
      myocardialInfarction: "",
      peacemakerIntoHeart: "",
    });
    setFemaleForm({
      pregnant: "",
      problemsDuringMenstrualCycle: "",
      takesOralContraceptives: "",
    });
    setFormOther({
      arthritis: "",
      badConditionOfGingiva: "",
      bronchialAsthma: "",
      chemotherapy: "",
      chronicBronchitis: "",
      currentActiveMedication: "",
      diabetes: "",
      epilepsy: "",
      hyperThyroidism: "",
      hypoThyroidism: "",
      liverDiseases: "",
      mentalDisease: "",
      osteoporosis: "",
      peridicMedication: "",
      radiotherapy: "",
      regularExposureToXRays: "",
      rheumaticFever: "",
      ssTs: "",
      stomachUlcer: "",
      suffersFromDisease: "",
      systemicDisease: "",
      tuberculosis: "",
      underMedicalSupervision: "",
    });
    setStatistics({
      customerLiftime: "",
      rating: "",
      totalBooked: "",
      totalCancelled: "",
      totalMissed: "",
      totalSpend: "",
    });
  };

  const handleOnBack = () => {
    setSelectedCustomerId(null);
    setCreateCustomer(null);
  };

  return (
    <>
      <div className="w-full h-full max-w-6xl mx-auto">
        <TopActions
        customersTotal={customersTotal}
          customers={customers}
          createCustomer={createCustomer}
          setCreateCustomer={setCreateCustomer}
          profile={profile}
          setProfile={setProfile}
          address={address}
          setAddress={setAddress}
          allergies={allergies}
          setAllergies={setAllergies}
          consumptions={consumptions}
          setConsumptions={setConsumptions}
          heartOrBloodIssues={heartOrBloodIssues}
          setHeartOrBloodIssues={setHeartOrBloodIssues}
          femaleForm={femaleForm}
          setFemaleForm={setFemaleForm}
          formOther={formOther}
          setFormOther={setFormOther}
          setStatistics={setStatistics}
          selectedCustomerId={selectedCustomerId}
          setSelectedCustomerId={setSelectedCustomerId}
          setDay={setDay}
          setMonth={setMonth}
          setYear={setYear}
          setActiveView={setActiveView}
        />

        <div className="bg-white rounded-xl border-[1px] border-[#ddecf3] shadow-lg md:h-[75vh] h-[70vh] flex flex-col">
          <div className="flex flex-col lg:flex-row h-full">
            <LeftSide
              customers={customers}
              setSelectedCustomerId={setSelectedCustomerId}
              selectedCustomerId={selectedCustomerId}
              setCustomers={setCustomers}
              setCreateCustomer={setCreateCustomer}
              createCustomer={createCustomer}
              setCustomersTotal={setCustomersTotal}
              setActiveView={setActiveView}
            />
            <div className="w-full overflow-y-auto scroll-smooth rounded-r-xl">
              {createCustomer || selectedCustomerId ? (
                <CustomerDetails
                  selectedCustomer={createCustomer ? null : selectedCustomerId}
                  activeView={activeView}
                  setActiveView={setActiveView}
                  onBack={handleOnBack}
                  profile={profile}
                  setProfile={setProfile}
                  address={address}
                  setAddress={setAddress}
                  allergies={allergies}
                  setAllergies={setAllergies}
                  consumptions={consumptions}
                  setConsumptions={setConsumptions}
                  heartOrBloodIssues={heartOrBloodIssues}
                  setHeartOrBloodIssues={setHeartOrBloodIssues}
                  femaleForm={femaleForm}
                  setFemaleForm={setFemaleForm}
                  formOther={formOther}
                  setFormOther={setFormOther}
                  statistics={statistics}
                  setStatistics={setStatistics}
                  day={day}
                  setDay={setDay}
                  month={month}
                  setMonth={setMonth}
                  year={year}
                  setYear={setYear}
                />
              ) : (
                <div className="hidden md:flex md:px-0 px-4 h-full w-full justify-center items-center">
                  <div className="flex flex-col items-center text-center mb-6">
                    <UserIcon className="h-12 w-12 text-primary " />
                    <h2 className="md:text-2xl text-xl font-semibold text-primary my-3">
                      Customers
                    </h2>
                    <p className="text-sm text-gray-600 md:w-2/3">
                      On this page you can find out more about your customers,
                      identify the most valuable clients and remind them to book
                      services again.
                    </p>
                    <button
                      onClick={handleOnCreateCustomerClick}
                      className="font-medium hover:bg-primary hover:text-white bg-secondary text-primary mt-7 flex items-center px-6 py-3 rounded-lg"
                    >
                      <Plus size={22} className="me-3" /> New Customer
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CustomerList;
