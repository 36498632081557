import ReactDOM from "react-dom";
import {
  CheckIcon,
  CrossIcon,
  MissedIcon,
  ReplayIcon,
} from "../../utils/Icons";

function Dropdown({ isOpen, position, onClose }) {
  const handleDropdownClick = (e) => {
    e.stopPropagation(); // Prevent dropdown from closing when clicked
  };

  if (!isOpen) return null;

  // Define approximate width and height of the dropdown
  const dropdownWidth = 240; // Width in pixels
  const dropdownHeight = 240; // Height in pixels
  const offset = 10; // Offset from the target element

  // Set initial position for dropdown
  let dropdownTop = position.top + offset;
  let dropdownLeft = position.left - 80;

  // Adjust for screen edges
  if (dropdownLeft + dropdownWidth > window.innerWidth) {
    dropdownLeft = window.innerWidth - dropdownWidth - offset; // Adjust for right overflow
  } else if (dropdownLeft < 0) {
    dropdownLeft = offset; // Adjust for left overflow
  }

  if (dropdownTop + dropdownHeight > window.innerHeight) {
    dropdownTop = window.innerHeight - dropdownHeight - offset; // Adjust for bottom overflow
  } else if (dropdownTop < 0) {
    dropdownTop = offset; // Adjust for top overflow
  }

  const dropdownStyle = {
    top: `${dropdownTop}px`,
    left: `${dropdownLeft}px`,
    position: "fixed",
    zIndex: 50,
  };

  return ReactDOM.createPortal(
    <>
      <div
        className="mt-3 rounded-xl absolute w-60 bg-white border-gray-100 border-[1px] shadow-lg p-4 max-h-60 overflow-y-auto"
        style={dropdownStyle}
        onClick={handleDropdownClick}
      >
        <ul>
          <li className="py-2 border-b border-[#ddecf3] cursor-pointer">
            <div className="flex items-center gap-4">
              <div className="rounded-full bg-[#0075FF] flex justify-center items-center p-3">
                <CheckIcon color="#ffff" />
              </div>
              <div>
                <h2 className="font-medium text-sm">Confirmed</h2>
                <h2 className="font-medium text-xs text-black text-opacity-60">
                  Accept this booking
                </h2>
              </div>
            </div>
          </li>
          <li className="py-2 border-b border-[#ddecf3] cursor-pointer">
            <div className="flex items-center gap-4">
              <div className="rounded-full bg-[#FFCA51] flex justify-center items-center p-3">
                <ReplayIcon />
              </div>
              <div>
                <h2 className="font-medium text-sm">Unconfirmed</h2>
                <h2 className="font-medium text-xs text-black text-opacity-60">
                  Waiting your confirmation
                </h2>
              </div>
            </div>
          </li>
          <li className="py-2 border-b border-[#ddecf3] cursor-pointer">
            <div className="flex items-center gap-4">
              <div className="rounded-full bg-[#FB5B8F] flex justify-center items-center p-3">
                <MissedIcon />
              </div>
              <div>
                <h2 className="font-medium text-sm">Missed</h2>
                <h2 className="font-medium text-xs text-black text-opacity-60">
                  Lorem Ipsum text
                </h2>
              </div>
            </div>
          </li>
          <li className="py-2 border-b border-[#ddecf3] cursor-pointer">
            <div className="flex items-center gap-4">
              <div className="rounded-full bg-[#55BAAE] flex justify-center items-center p-3">
                <CheckIcon color="#ffff" />
              </div>
              <div>
                <h2 className="font-medium text-sm">Completed</h2>
                <h2 className="font-medium text-xs text-black text-opacity-60">
                  Lorem Ipsum text
                </h2>
              </div>
            </div>
          </li>
          <li className="py-2 border-b border-[#ddecf3] cursor-pointer">
            <div className="flex items-center gap-4">
              <div className="rounded-full bg-[#999999] flex justify-center items-center p-3">
                <CrossIcon />
              </div>
              <div>
                <h2 className="font-medium text-sm">Cancelled</h2>
                <h2 className="font-medium text-xs text-black text-opacity-60">
                  Cancel this booking
                </h2>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </>,
    document.body
  );
}

export default Dropdown;
