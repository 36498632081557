import React from "react";
import ButtonLoader from "../Common/ButtonLoader";

function DeleteDialog({ isOpen, onClose, onDelete, loading }) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 max-w-md w-full shadow-lg">
        <h2 className="text-lg font-bold mb-4">
          Are you sure you want to delete this staff member?
        </h2>
        <p className="text-gray-600 mb-6">
          This action cannot be undone. This will permanently delete the staff
          member and remove their data from our servers.
        </p>
        <div className="flex justify-end space-x-4">
          <button
            className="px-4 py-2 bg-gray-100 text-gray-700 hover:bg-gray-200 rounded-lg"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="px-4 py-2 bg-red-100 text-red-700 hover:bg-red-200 rounded-lg"
            onClick={onDelete}
            disabled={loading}
          >
            {loading ? <ButtonLoader /> : "Delete"}
          </button>
        </div>
      </div>
    </div>
  );
}

export default DeleteDialog;
