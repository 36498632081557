import React, { useRef, useState } from "react";
import { DotsIcon } from "../../utils/Icons";
import Dropdown from "./Dropdown";

function SettingTab() {

  const dotsRef = useRef(null);

  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const handleDotsClick = () => {
    if (dotsRef.current) {
      const rect = dotsRef.current.getBoundingClientRect();
      setDropdownPosition({ top: rect.bottom, left: rect.left });
    }
    setDropdownOpen(!isDropdownOpen);
  };

  const handleDropdownClose = () => setDropdownOpen(false);

  return (
    <div className="my-5 px-5">
      <div className="w-full">
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white border-collapse text-center">
            <thead>
              <tr className="text-gray-600 text-sm md:text-md">
                <th className="py-5 px-6 border-b border-[#DDECF3]">When</th>
                <th className="py-5 px-6 border-b border-[#DDECF3]">Service</th>
                <th className="py-5 px-6 border-b border-[#DDECF3]">Price</th>
                <th className="py-5 px-6 border-b border-[#DDECF3]">Status</th>
              </tr>
            </thead>
            <tbody className="text-gray-700 text-sm">
              <tr className="border-b border-[#DDECF3]">
                <td className="py-5 px-6">4th March 2024 04:00 AM</td>
                <td className="py-5 px-6 text-primary">Filling</td>
                <td className="py-5 px-6">
                  <div className="flex items-center justify-center gap-4">
                    <h2 className="text-primary">Lek 25</h2>
                    <div className="p-1 rounded-xl border-2 border-[#DDECF3]">
                      <h2 className="font-medium text-black text-opacity-50 px-2">
                        Not Paid
                      </h2>
                    </div>
                  </div>
                </td>
                <td className="py-5 px-6 text-primary relative">
                  <div className="flex items-center justify-center gap-3">
                    <div className="rounded-xl bg-[#55BAAE]">
                      <h2 className="font-medium text-white px-5 py-2">
                        Confirmed
                      </h2>
                    </div>
                    <div
                      ref={dotsRef}
                      className="rounded-lg flex justify-center items-center w-8 h-8 bg-[#999999] cursor-pointer"
                      onClick={handleDotsClick}
                    >
                      <DotsIcon />
                    </div>
                  </div>
                  <Dropdown
                    isOpen={isDropdownOpen}
                    position={dropdownPosition}
                    onClose={handleDropdownClose}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

export default SettingTab;
