import { Bell, Globe, GraduationCap, HelpCircle } from "lucide-react";
import React from "react";
import { Link } from "react-router-dom";

function Topbar() {
  return (
    <nav className="flex items-center justify-between px-4 py-2 bg-white shadow-sm">
      <Link to="/" className="flex items-center ">
        <img
          src="/assets/images/logo.png"
          alt="logodark"
          width={145}
          height={26}
        />
      </Link>
      <div className="flex items-center space-x-4">
        <button variant="ghost" size="icon">
          <Globe className="h-6 w-6 text-primary" />
          <span className="sr-only">Language</span>
        </button>
        <button variant="ghost" size="icon">
          <Bell className="h-6 w-6 text-primary" />
          <span className="sr-only">Notifications</span>
        </button>
        <button variant="ghost" size="icon">
          <GraduationCap className="h-6 w-6 text-primary" />
          <span className="sr-only">Education</span>
        </button>
        <button variant="ghost" size="icon">
          <HelpCircle className="h-6 w-6 text-primary" />
          <span className="sr-only">Help</span>
        </button>
      </div>
    </nav>
  );
}

export default Topbar;
