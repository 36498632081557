import React from "react";

function CustomerSidebar({
  customers,
  onCustomerSelect,
  selectedCustomerId,
  setCreateCustomer,
  setActiveView,
}) {
  const handleOnClick = (id) => {
    onCustomerSelect(id);
    setCreateCustomer(false);
    setActiveView("details");
  };

  return (
    <div className="mb-2 w-full">
      {customers.length === 0 ? (
        <div className="text-center p-4">No customers found</div>
      ) : (
        customers.map((customer) => {
          // Create initials dynamically
          const initials = `${customer.firstName[0] || ""}${customer.lastName[0] || ""}`.toUpperCase();

          return (
            <div
              key={customer.id}
              onClick={() => handleOnClick(customer.id)}
              className={`flex items-center space-x-4 p-4 border-b border-[#ddecf3] cursor-pointer ${
                selectedCustomerId === customer.id
                  ? "bg-secondary text-primary"
                  : ""
              }`}
            >
              <div className="w-10 h-10 rounded-full flex items-center justify-center text-white bg-gray-400">
                {initials}
              </div>
              <div className="ms-5">
                <div className="font-medium">{`${customer.firstName} ${customer.lastName}`}</div>
                <div className="text-sm text-gray-500">
                  {customer.phoneNumber}
                </div>
              </div>
            </div>
          );
        })
      )}
    </div>
  );
}

export default CustomerSidebar;
