import React from "react";
import Layout from "../Components/Common/Layout";
import CalenderTable from "../Components/Calender/CalenderTable";

function Calender() {
  return (
    <Layout>
      <CalenderTable />
    </Layout>
  );
}

export default Calender;
