import React, { useEffect, useState } from "react";
import Tabs from "./Tabs";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import DetailTab from "./DetailTab";
import SettingTab from "./SettingTab";
import Delete from "./Delete";
import { useNavigate } from "react-router-dom";
import { checkAuthAndRedirect } from "../Common/RefreshToken";

function ServiceDetails({
  selectedService,
  activeView,
  setActiveView,
  onBack,
  serviceRequest,
  setServiceRequest,
}) {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const loggedUserData = useSelector((state) => state.userData.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const fetchservices = async () => {
    setLoading(true);
    const token = await checkAuthAndRedirect(navigate, dispatch, loggedUserData);
    const url = `${process.env.REACT_APP_API_URL}/api/v1/services/${selectedService}`;

    const options = {
      method: "GET",
      headers: {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const response = await fetch(url, options);
      const data = await response.json();

      if (response.ok) {
        setServiceRequest(data);
      } else {
        console.error("Error:", data);
        toast.error(data.detail || "Internal Server Error");
      }
    } catch (error) {
      console.error("Catch error:", error);
      toast.error("Internal Server Error");
    } finally {
      setLoading(false);
    }
  };
  const handleDeleteConfirm = async () => {
    setLoading(true);
    const token = await checkAuthAndRedirect(navigate, dispatch, loggedUserData);
    try {
      const url = `${process.env.REACT_APP_API_URL}/api/v1/services/${selectedService}`;
      const options = {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await fetch(url, options);

      if (response.ok) {
        toast.success("Staff deleted successfully");

        setIsDeleteDialogOpen(false);
        setLoading(false);
        window.location.reload();
      } else {
        const errorData = await response.json();
        toast.error(errorData.detail || "Failed to delete Staff");
        setLoading(false);
      }
    } catch (error) {
      toast.error("Error deleting customer");
      setLoading(false);
    }
  };
  useEffect(() => {
    if (selectedService != null) {
      fetchservices();
    }
    // eslint-disable-next-line
  }, [selectedService]);
  return (
    <>
      <div className="h-full w-full">
        <div className="flex items-center justify-between px-5">
          <button
            onClick={onBack}
            className="lg:hidden  flex items-center  mt-4 mb-4 text-primary hover:text-[#ddecf3]  rounded-lg"
          >
            <span className="mr-2">←</span> Back
          </button>
          <div className="flex items-center">
            <h2 className="lg:hidden font-medium text-black">
              {selectedService?.category}
            </h2>
            <h2 className="lg:hidden font-semibold text-primary ">
              ({selectedService?.name})
            </h2>
          </div>
        </div>
        <Tabs
          selectedService={selectedService}
          activeView={activeView}
          setActiveView={setActiveView}
          setIsDeleteDialogOpen={setIsDeleteDialogOpen}
        />
        {activeView === "details" && (
          <DetailTab
            serviceRequest={serviceRequest}
            setServiceRequest={setServiceRequest}
          />
        )}
        {activeView === "settings" && <SettingTab />}
      </div>
      {isDeleteDialogOpen && (
        <Delete
          isOpen={isDeleteDialogOpen}
          onClose={() => setIsDeleteDialogOpen(false)}
          onDelete={handleDeleteConfirm}
          loading={loading}
        />
      )}
    </>
  );
}

export default ServiceDetails;
