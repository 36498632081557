import { ChevronDown, ChevronUp } from "lucide-react";
import React, { useState, useEffect } from "react";

function AssignedServices({
  services,
  staffProfileCreateRequest,
  setStaffProfileCreateRequest,
}) {
  const [selectedServices, setSelectedServices] = useState({});
  const [openCategories, setOpenCategories] = useState({});

  useEffect(() => {
    const initializeSelectedServices = services.reduce((acc, category) => {
      acc[category.id] = {
        isSelected: category.services.every((service) =>
          staffProfileCreateRequest.servicesAssigned.includes(service.id)
        ),
        services: category.services.reduce((serviceAcc, service) => {
          serviceAcc[service.id] =
            staffProfileCreateRequest.servicesAssigned.includes(service.id);
          return serviceAcc;
        }, {}),
      };
      return acc;
    }, {});

    setSelectedServices(initializeSelectedServices);
  }, [services, staffProfileCreateRequest.servicesAssigned]);

  const toggleCategoryDropdown = (categoryId) => {
    setOpenCategories((prevState) => ({
      ...prevState,
      [categoryId]: !prevState[categoryId],
    }));
  };

  const handleAssignedServicesCheckboxChange = (checked) => {
    const updatedSelectedServices = services.reduce((acc, category) => {
      acc[category.id] = {
        isSelected: checked,
        services: category.services.reduce(
          (serviceAcc, service) => ({
            ...serviceAcc,
            [service.id]: checked,
          }),
          {}
        ),
      };
      return acc;
    }, {});

    setSelectedServices(updatedSelectedServices);
    updateServicesAssigned(updatedSelectedServices);
  };

  const handleCategoryCheckboxChange = (categoryId, isSelected) => {
    const updatedSelectedServices = {
      ...selectedServices,
      [categoryId]: {
        isSelected,
        services: Object.keys(selectedServices[categoryId].services).reduce(
          (acc, serviceId) => {
            acc[serviceId] = isSelected;
            return acc;
          },
          {}
        ),
      },
    };

    setSelectedServices(updatedSelectedServices);
    updateServicesAssigned(updatedSelectedServices);
  };

  const handleServiceCheckboxChange = (categoryId, serviceId, isSelected) => {
    const updatedCategoryServices = {
      ...selectedServices[categoryId].services,
      [serviceId]: isSelected,
    };

    const isCategorySelected = Object.values(updatedCategoryServices).every(
      (value) => value
    );

    const updatedSelectedServices = {
      ...selectedServices,
      [categoryId]: {
        isSelected: isCategorySelected,
        services: updatedCategoryServices,
      },
    };

    setSelectedServices(updatedSelectedServices);
    updateServicesAssigned(updatedSelectedServices);
  };

  const updateServicesAssigned = (updatedSelectedServices) => {
    const selectedServiceIds = Object.entries(updatedSelectedServices).flatMap(
      ([, { services }]) =>
        Object.entries(services)
          .filter(([, isSelected]) => isSelected)
          .map(([serviceId]) => serviceId)
    );

    // Update only if the selected services actually changed
    if (
      JSON.stringify(staffProfileCreateRequest.servicesAssigned) !==
      JSON.stringify(selectedServiceIds)
    ) {
      setStaffProfileCreateRequest((prevRequest) => ({
        ...prevRequest,
        servicesAssigned: selectedServiceIds,
      }));
    }
  };

  const isAllSelected = services.every((category) =>
    category.services.every(
      (service) => selectedServices[category.id]?.services[service.id]
    )
  );

  return (
    <div className="md:col-span-6">
      <div className="border rounded-lg mt-6 border-[#ddecf3] bg-[#F0F5F7] py-2 px-4 flex items-center">
        <input
          type="checkbox"
          className="w-4 h-4 me-3"
          checked={isAllSelected}
          onChange={(e) =>
            handleAssignedServicesCheckboxChange(e.target.checked)
          }
        />
        <div>Assigned Services</div>
      </div>

      {services.length > 0 &&
        services.map((category) => (
          <div key={category.id}>
            <div className="border rounded-lg mt-6 border-[#ddecf3] bg-[#F0F5F7] py-2 px-4 flex items-center cursor-pointer">
              <input
                type="checkbox"
                className="w-4 h-4 me-3"
                checked={selectedServices[category.id]?.isSelected || false}
                onChange={(e) =>
                  handleCategoryCheckboxChange(category.id, e.target.checked)
                }
              />
              <div className="flex justify-between items-center w-full">
                <span>{category.name}</span>
                <span
                  onClick={() => toggleCategoryDropdown(category.id)}
                  className="cursor-pointer"
                >
                  {openCategories[category.id] ? (
                    <ChevronUp size={16} />
                  ) : (
                    <ChevronDown size={16} />
                  )}
                </span>
              </div>
            </div>

            {openCategories[category.id] && (
              <div className="space-y-4 mt-4 pl-8">
                {category.services.map((service) => (
                  <div key={service.id} className="flex items-center gap-3">
                    <input
                      type="checkbox"
                      className="w-4 h-4"
                      checked={
                        selectedServices[category.id]?.services[service.id] ||
                        false
                      }
                      onChange={(e) =>
                        handleServiceCheckboxChange(
                          category.id,
                          service.id,
                          e.target.checked
                        )
                      }
                    />
                    {service.name}
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
    </div>
  );
}

export default AssignedServices;
