import { Plus } from "lucide-react";
import React, { useState } from "react";
import { CheckIcon } from "../../utils/Icons";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import ButtonLoader from "../Common/ButtonLoader";
import { useNavigate } from "react-router-dom";
import { checkAuthAndRedirect } from "../Common/RefreshToken";

export default function TopActions({
  customersTotal,
  customers,
  createCustomer,
  setCreateCustomer,
  profile,
  setProfile,
  address,
  setAddress,
  allergies,
  setAllergies,
  consumptions,
  setConsumptions,
  heartOrBloodIssues,
  setHeartOrBloodIssues,
  femaleForm,
  setFemaleForm,
  formOther,
  setFormOther,
  setStatistics,
  selectedCustomerId,
  setSelectedCustomerId,
  setDay,
  setMonth,
  setYear,
  setActiveView
}) {
  const [loading, setLoading] = useState(false);
  const loggedUserData = useSelector((state) => state.userData.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleOnCreateCustomerClick = () => {
    setCreateCustomer(true);
    setSelectedCustomerId(null);
    setActiveView("details");
    setDay("");
    setMonth("");
    setYear("");
    setProfile({
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      birthdate: "",
      gender: 0,
      occupation: "",
      notes: "",
    });
    setAddress({
      addressLine: "",
      zip: "",
      city: "",
      countryCode: "",
    });
    setAllergies({
      allergicMedications: "",
      allergyFromAntibiotic: "",
      allergyFromAnythingElse: "",
      allergyFromLocalAnesthesia: "",
      allergyFromMetals: "",
    });
    setConsumptions({
      alcohoolConsumption: "",
      drugTaking: "",
      tabacooConsumption: "",
    });
    setHeartOrBloodIssues({
      bloodDisease: "",
      bloodTransfusion: "",
      congenitalHeartDisease: "",
      coronaryInsufficiency: "",
      heartSurgeryIntervention: "",
      majorHaemorrhageAfterAnyIntervention: "",
      highBlodPressure: "",
      lowBloodPressure: "",
      myocardialInfarction: "",
      peacemakerIntoHeart: "",
    });
    setFemaleForm({
      pregnant: "",
      problemsDuringMenstrualCycle: "",
      takesOralContraceptives: "",
    });
    setFormOther({
      arthritis: "",
      badConditionOfGingiva: "",
      bronchialAsthma: "",
      chemotherapy: "",
      chronicBronchitis: "",
      currentActiveMedication: "",
      diabetes: "",
      epilepsy: "",
      hyperThyroidism: "",
      hypoThyroidism: "",
      liverDiseases: "",
      mentalDisease: "",
      osteoporosis: "",
      peridicMedication: "",
      radiotherapy: "",
      regularExposureToXRays: "",
      rheumaticFever: "",
      ssTs: "",
      stomachUlcer: "",
      suffersFromDisease: "",
      systemicDisease: "",
      tuberculosis: "",
      underMedicalSupervision: "",
    });
    setStatistics({
      customerLiftime: "",
      rating: "",
      totalBooked: "",
      totalCancelled: "",
      totalMissed: "",
      totalSpend: "",
    });
  };

  const validateCustomer = () => {
    let isValid = true;

    if (!profile.firstName || profile.firstName.trim() === "") {
      toast.error("First name is required.");
      isValid = false;
      return;
    }

    if (!profile.lastName || profile.lastName.trim() === "") {
      toast.error("Last name is required.");
      isValid = false;
      return;
    }

    if (!profile.birthdate || profile.birthdate.trim() === "") {
      toast.error("Birthdate is required.");
      isValid = false;
      return isValid;
    } else {
      const birthYear = new Date(profile.birthdate).getFullYear();
      if (birthYear > 2022) {
        toast.error("Birth year must not be greater than 2022.");
        isValid = false;
        return isValid;
      }
    }
    if (!address.countryCode || address.countryCode.trim() === "") {
      toast.error("Country is required.");
      isValid = false;
      return;
    }

    return isValid;
  };

  const handleCreateCustomer = async (event) => {
    event.preventDefault();
    setLoading(true);
    const token = await checkAuthAndRedirect(navigate, dispatch, loggedUserData);

    if (!validateCustomer()) {
      setLoading(false);
      return;
    }

    const url = `${process.env.REACT_APP_API_URL}/api/v1/customers`;
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        profile,
        address,
        allergies,
        consumptions,
        heartOrBloodIssues,
        femaleForm,
        formOther,
      }),
    };

    try {
      const response = await fetch(url, options);
      const data = await response.json();
      if (response.ok) {
        toast.success("Customer Added Successfully!");
        setSelectedCustomerId(data.id);
        setCreateCustomer(false);
        setLoading(false);
      } else {
        toast.error(data.detail || "Please validate the Fields");
        setLoading(false);
      }
    } catch (error) {
      console.error("Catch error:", error);
      toast.error("Internal Server Error");
      setLoading(false);
    }
  };

  const handleUpdateCustomer = async (event) => {
    event.preventDefault();
    setLoading(true);
    const token = await checkAuthAndRedirect(navigate, dispatch, loggedUserData);

    if (!validateCustomer()) {
      setLoading(false);
      return;
    }

    const url = `${process.env.REACT_APP_API_URL}/api/v1/customers`;
    const options = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        profile,
        address,
        allergies,
        consumptions,
        heartOrBloodIssues,
        femaleForm,
        formOther,
        id: selectedCustomerId,
      }),
    };

    try {
      const response = await fetch(url, options);
      if (response.ok) {
        toast.success("Customer Updated Successfully!");
        setLoading(false);
      } else {
        const errorData = await response.json();
        toast.error(errorData.detail || "Please validate the Fields");
        setLoading(false);
      }
    } catch (error) {
      console.error("Catch error:", error);
      toast.error("Internal Server Error");
      setLoading(false);
    }
  };
  return (
    <div className="flex justify-between items-center mb-6">
      <div className="flex items-center space-x-2">
        <span className="text-sm font-normal bg-secondary text-primary rounded px-3 py-1">
          {customersTotal}
        </span>
        <h2 className="font-bold text-xl">Customers</h2>
      </div>
      <div className="flex gap-3">
        {createCustomer ? (
          <button
            onClick={handleCreateCustomer}
            disabled={loading}
            className="flex items-center gap-2 justify-center font-medium text-white bg-primary md:px-5 px-4 md:py-3 py-2 rounded-lg"
          >
            {loading ? (
              <div className="w-full flex justify-center">
                <ButtonLoader />
              </div>
            ) : (
              <>
                <CheckIcon size={16} color="#ffff" />
                <h2>Save</h2>
              </>
            )}
          </button>
        ) : (
          ""
        )}

        {selectedCustomerId ? (
          <button
            onClick={handleUpdateCustomer}
            className="flex items-center gap-2 justify-center font-medium text-white bg-primary md:px-5 px-4 md:py-3 py-2 rounded-lg"
          >
            {loading ? (
              <div className="w-full flex justify-center">
                <ButtonLoader />
              </div>
            ) : (
              <>
                <CheckIcon size={16} color="#ffff" />
                <h2>Save</h2>
              </>
            )}
          </button>
        ) : (
          ""
        )}

        <button
          onClick={handleOnCreateCustomerClick}
          className="flex items-center space-x-2 font-medium text-primary hover:bg-primary hover:text-white bg-secondary md:px-4 px-2 py-2 rounded-lg"
        >
          <Plus size={22} />
          <h2 className="hidden md:block">New Customer</h2>
        </button>
      </div>
    </div>
  );
}
