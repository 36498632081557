import React from "react";

function SettingTab() {
  return (
    <div className="my-10 px-5 grid grid-cols-1 md:grid-cols-12 gap-5">
      <div className="md:col-span-6">
        <div className="mb-5 border rounded-lg  border-[#ddecf3] bg-[#F0F5F7] py-2 ps-4">
          Buffer Time
        </div>
        <div className="flex items-center gap-2 mb-2">
          <div className="flex-1 py-2 px-4 border-[1px] rounded-lg border-[#ddecf3]">
            <label
              htmlFor="price"
              className="block text-xs text-black text-opacity-50"
            >
              Prior (mins)
            </label>
            <input
              type="text"
              id="price"
              value="0"
              className="block w-full border-none outline-none"
            />
          </div>
          <div className="flex-1 py-2 px-4 border-[1px] rounded-lg border-[#ddecf3]">
            <label
              htmlFor="cost"
              className="block text-xs text-black text-opacity-50"
            >
              After (mins)
            </label>
            <input
              type="text"
              id="cost"
              value="0"
              className="block w-full border-none outline-none"
            />
          </div>
        </div>
        <div className="border rounded-lg border-[#ddecf3] bg-[#F0F5F7] py-2 ps-4">
          Online Booking
        </div>
        <div className="md:mb-2 mt-5 md:mt-4">
          <div className="flex items-center gap-3">
            <label className="relative inline-flex items-center cursor-pointer">
              <input type="checkbox" className="sr-only peer" value="" />
              <div className="group peer bg-white rounded-full duration-300 w-12 h-6 ring-2 ring-[#ddecf3] after:duration-300 after:bg-[#ddecf3] peer-checked:after:bg-primary peer-checked:ring-primary after:rounded-full after:absolute after:h-5 after:w-5 after:top-0.5 after:left-0.5 after:flex after:justify-center after:items-center peer-checked:after:translate-x-6 peer-hover:after:scale-95"></div>
            </label>

            <h2 className=" md:text-md text-sm">Enable Online Booking </h2>
          </div>
        </div>
      </div>
      <div className="md:col-span-6">
        <div className="md:mb-5 mb-2 border rounded-lg  border-[#ddecf3] bg-[#F0F5F7] py-2 px-4 flex items-center justify-between">
          <div>Redirect After Booking</div>
        </div>
        <div className="mb-2 flex-1 py-2 px-4 border-[1px] rounded-lg border-[#ddecf3]">
          <label
            htmlFor="cost"
            className="block text-xs text-black text-opacity-50"
          >
            Redirect to this link
          </label>
          <input
            type="text"
            id="cost"
            value="https://loremipsum.com"
            className="block w-full border-none outline-none"
          />
        </div>
        <div className="border rounded-lg border-[#ddecf3] bg-[#F0F5F7] py-2 ps-4">
          Video Call
        </div>
        <div className="md:mb-2 mt-4 md:mt-4">
          <div className="flex items-center gap-3">
            <label className="relative inline-flex items-center cursor-pointer">
              <input type="checkbox" className="sr-only peer" value="" />
              <div className="group peer bg-white rounded-full duration-300 w-12 h-6 ring-2 ring-[#ddecf3] after:duration-300 after:bg-[#ddecf3] peer-checked:after:bg-primary peer-checked:ring-primary after:rounded-full after:absolute after:h-5 after:w-5 after:top-0.5 after:left-0.5 after:flex after:justify-center after:items-center peer-checked:after:translate-x-6 peer-hover:after:scale-95"></div>
            </label>

            <h2 className="md:text-md text-sm">Resource Required </h2>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SettingTab;
