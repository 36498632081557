import React, { useEffect, useState } from "react";
import AddRecalModal from "./AddRecalModal";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import ButtonLoader from "../Common/ButtonLoader";
import DetailTab from "./DetailTab";
import SettingTab from "./SettingTab";
import FormsTab from "./FormsTab";
import RecallTab from "./RecallTab";
import DeleteDialog from "./DeleteDialog";
import Tabs from "./Tabs";
import { checkAuthAndRedirect } from "../Common/RefreshToken";
import { useNavigate } from "react-router-dom";
import AuditTab from "./AuditTab";

function CustomerDetails({
  selectedCustomer,
  activeView,
  setActiveView,
  onBack,
  profile,
  setProfile,
  address,
  setAddress,
  allergies,
  setAllergies,
  consumptions,
  setConsumptions,
  heartOrBloodIssues,
  setHeartOrBloodIssues,
  femaleForm,
  setFemaleForm,
  formOther,
  setFormOther,
  statistics,
  setStatistics,
  day,
  setDay,
  month,
  setMonth,
  year,
  setYear,
}) {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const loggedUserData = useSelector((state) => state.userData.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const fetchCustomer = async () => {
    setLoading(true);
    const token = await checkAuthAndRedirect(navigate, dispatch, loggedUserData);
    const url = `${process.env.REACT_APP_API_URL}/api/v1/customers/${selectedCustomer}`;

    const options = {
      method: "GET",
      headers: {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const response = await fetch(url, options);
      const data = await response.json();

      if (response.ok) {
        setProfile(data.profile);
        setAddress(data.address);
        setAllergies(data.allergies);
        setConsumptions(data.consumptions);
        setHeartOrBloodIssues(data.heartOrBloodIssues);
        setFemaleForm(data.femaleForm);
        setFormOther(data.formOther);
        setStatistics(data.statistics);
        if (data?.profile?.birthdate) {
          const [yearValue, monthValue, dayValue] =
            data?.profile?.birthdate.split("-");
          setDay(dayValue);
          setMonth(monthValue);
          setYear(yearValue);
        }
      } else {
        console.error("Error:", data);
        toast.error(data.detail || "Internal Server Error");
      }
    } catch (error) {
      console.error("Catch error:", error);
      toast.error("Internal Server Error");
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteConfirm = async () => {
    setLoading(true);
    try {
      const token = await checkAuthAndRedirect(navigate, dispatch, loggedUserData);
      const url = `${process.env.REACT_APP_API_URL}/api/v1/customers/${selectedCustomer}`;
      const options = {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await fetch(url, options);

      if (response.ok) {
        toast.success("Customer deleted successfully");

        setIsDeleteDialogOpen(false);
        setLoading(false);
        window.location.reload();
      } else {
        const errorData = await response.json();
        toast.error(errorData.message || "Failed to delete customer");
        setLoading(false);
      }
    } catch (error) {
      toast.error("Error deleting customer");
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedCustomer != null) {
      fetchCustomer();
    }
    // eslint-disable-next-line
  }, [selectedCustomer]);

  if (loading) {
    return (
      <div className="w-full h-full flex justify-center items-center">
        <ButtonLoader />
      </div>
    );
  }
  return (
    <>
      <div className="h-full w-full">
        <div className="flex items-center">
          <button
            onClick={onBack}
            className="lg:hidden flex items-center px-3 mt-4 mb-4 text-primary hover:text-[#ddecf3]  rounded-lg"
          >
            <span className="mr-2">←</span> Back
          </button>
          <h2 className="lg:hidden font-semibold text-black ml-3">
            {profile?.firstName} {profile?.lastName}
          </h2>
        </div>
        <Tabs
          selectedCustomer={selectedCustomer}
          activeView={activeView}
          setActiveView={setActiveView}
          setIsDeleteDialogOpen={setIsDeleteDialogOpen}
        />
        {/* Rendering based on activeView */}
        {activeView === "details" && (
          <DetailTab
            profile={profile}
            setProfile={setProfile}
            address={address}
            setAddress={setAddress}
            statistics={statistics}
            day={day}
            month={month}
            year={year}
            setDay={setDay}
            setMonth={setMonth}
            setYear={setYear}
          />
        )}
        {activeView === "settings" && <SettingTab />}
        {activeView === "settings" && (
          <div className="my-10 px-5">{/* Booking details content */}</div>
        )}
        {activeView === "recalls" && <RecallTab setModalOpen={setModalOpen} />}
        {activeView === "forms" && (
          <FormsTab
            allergies={allergies}
            setAllergies={setAllergies}
            consumptions={consumptions}
            setConsumptions={setConsumptions}
            heartOrBloodIssues={heartOrBloodIssues}
            setHeartOrBloodIssues={setHeartOrBloodIssues}
            femaleForm={femaleForm}
            setFemaleForm={setFemaleForm}
            formOther={formOther}
            setFormOther={setFormOther}
          />
        )}
        {activeView === "audit" && (
          <AuditTab selectedCustomer={selectedCustomer} />
        )}
      </div>
      <AddRecalModal isOpen={isModalOpen} onClose={() => setModalOpen(false)} />
      {isDeleteDialogOpen && (
        <DeleteDialog
          isOpen={isDeleteDialogOpen}
          onClose={() => setIsDeleteDialogOpen(false)}
          onDelete={handleDeleteConfirm}
          loading={loading}
        />
      )}
    </>
  );
}

export default CustomerDetails;
