import { Key } from "lucide-react";
import React from "react";

function ChangePassword() {
  return (
    <div className="space-y-4 border bg-white rounded-xl shadow-lg">
      <h2 className="text-sm font-medium text-primary border-b p-3 rounded-t-xl bg-gradient-to-r from-white to-gray-100 border-secondary">
        Change Password
      </h2>
      <div className="px-3 pb-8">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="relative">
            <Key className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
            <input
              type="password"
              placeholder="Password"
              className="pl-10 border w-full py-4 rounded-md"
            />
          </div>
          <div className="relative">
            <Key className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
            <input
              type="password"
              placeholder="New Password"
              className="pl-10 border w-full py-4 rounded-md"
            />
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-6">
          <div className="relative">
            <Key className="absolute left-3 top-1/2 transform -translate-y-1/2 h-5 w-5 text-gray-400" />
            <input
              type="password"
              placeholder="Confirm Password"
              className="pl-10 border w-full py-4 rounded-md"
            />
          </div>
          <button className="w-full bg-secondary text-primary font-normal p-4 rounded-md">
            Change Password
          </button>
        </div>
      </div>
    </div>
  );
}

export default ChangePassword;
