import React from 'react'

function ContactDetail() {
  return (
    <div className="space-y-4 border bg-white rounded-xl shadow-lg">
          <h2 className="text-sm font-medium text-primary border-b p-3 rounded-t-xl bg-gradient-to-r from-white to-gray-100 border-secondary">
            Contact Details
          </h2>
          <div className="px-3 pb-8">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <label
                  htmlFor="fullName"
                  className="block text-xs font-normal text-gray-500 mb-1"
                >
                  Full Name
                </label>
                <input
                  id="fullName"
                  defaultValue="Gerinzo Cibuku"
                  className="pl-5 border w-full py-4 rounded-md"
                />
              </div>
              <div>
                <label
                  htmlFor="country"
                  className="block text-xs font-normal text-gray-500 mb-1"
                >
                  Country
                </label>
                <select
                  id="country"
                  defaultValue="albania"
                  className="pl-5 border w-full py-4 rounded-md"
                >
                  <option value="albania">Albania</option>
                </select>
              </div>
              <div>
                <label
                  htmlFor="city"
                  className="block text-xs font-normal text-gray-500 mb-1"
                >
                  City
                </label>
                <select
                  id="city"
                  defaultValue="tirana"
                  className="pl-5 border w-full py-4 rounded-md"
                >
                  <option value="tirana">Tirana</option>
                </select>
              </div>
              <div>
                <label
                  htmlFor="address"
                  className="block text-xs font-normal text-gray-500 mb-1"
                >
                  Address
                </label>
                <input
                  id="address"
                  defaultValue="Bulevardi Zogu 1"
                  className="pl-5 border w-full py-4 rounded-md"
                />
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-6">
              <div>
                <label
                  htmlFor="zipCode"
                  className="block text-xs font-normal text-gray-500 mb-1"
                >
                  Zip Code
                </label>
                <input
                  id="zipCode"
                  defaultValue="1001"
                  className="pl-5 border w-full py-4 rounded-md"
                />
              </div>
              <div>
                <label
                  htmlFor="email"
                  className="block text-xs font-normal text-gray-500 mb-1"
                >
                  Email
                </label>
                <input
                  id="email"
                  type="email"
                  defaultValue="emer.mbiemer@gmail.com"
                  className="pl-5 border w-full py-4 rounded-md"
                />
              </div>
              <div>
                <label
                  htmlFor="phone"
                  className="block text-xs font-normal text-gray-500 mb-1"
                >
                  Phone number
                </label>
                <div className="flex items-center gap-2">
                  <select
                    defaultValue="355"
                    className="w-[100px] py-4 border border-gray-300 rounded-md"
                  >
                    <option value="355">+355</option>
                  </select>
                  <input
                    id="phone"
                    className="w-full md:w-[250px] pl-5 border border-gray-300  py-4 rounded-md"
                    defaultValue="684045205"
                  />
                </div>
              </div>
              <div className="mt-5">
                <button className="w-full bg-secondary text-primary font-normal p-4 rounded-md">
                  Update Contact Details
                </button>
              </div>
            </div>
          </div>
        </div>
  )
}

export default ContactDetail
