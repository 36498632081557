import React, { useState, useEffect } from "react";
import FieldLabel from "../Common/FieldLabel";
import { BinIcon } from "../../utils/Icons";
import { PlusIcon } from "lucide-react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import ButtonLoader from "../Common/ButtonLoader";
import { useNavigate } from "react-router-dom";
import { checkAuthAndRedirect } from "../Common/RefreshToken";

function AssignedStaff({ serviceRequest, setServiceRequest }) {
  const [searchQuery, setSearchQuery] = useState("");
  const [staffs, setStaffs] = useState([]);
  const [loading, setLoading] = useState(false);
  const loggedUserData = useSelector((state) => state.userData.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const fetchStaffs = async () => {
    if (searchQuery.length < 3) {
      setStaffs([]);
      return;
    }

    setLoading(true);
    const token = await checkAuthAndRedirect(navigate, dispatch, loggedUserData);

    const sanitizedQuery = encodeURIComponent(
      searchQuery.replace(/[^a-zA-Z0-9 ]/g, "")
    );

    const url = `${process.env.REACT_APP_API_URL}/api/v1/staff?Search=${sanitizedQuery}`;

    const options = {
      method: "GET",
      headers: {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const response = await fetch(url, options);
      const data = await response.json();

      if (response.ok) {
        if (data.list && data.list.length > 0) {
          setStaffs(data.list);
        } else {
          setStaffs([]);
          toast.info("No staff found for the given search query.", {
            toastId: "no_staff_found",
          });
        }
      } else {
        toast.error(data.detail || "Internal Server Error", {
          toastId: "server_error",
        });
        setStaffs([]);
      }
    } catch (error) {
      toast.error("Internal Server Error", {
        toastId: "fetch_error",
      });
      setStaffs([]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchStaffs();
    // eslint-disable-next-line
  }, [searchQuery]);

  const handleSearchChange = (value) => {
    setSearchQuery(value);
  };

  const handleAddStaff = (staff) => {
    setSearchQuery("");
    setServiceRequest((prevServiceRequest) => {
      const alreadyAssigned = prevServiceRequest.assignedStaff?.some(
        (s) => s.staffId === staff.id
      );

      if (alreadyAssigned) {
        toast.info("Staff is already assigned.", {
          toastId: `assigned_${staff.id}`,
        });
        return prevServiceRequest;
      }

      const newStaff = {
        staffId: staff.id,
        staffName: staff.firstName,
        staffSurname: staff.lastName,
      };

      return {
        ...prevServiceRequest,
        assignedStaff: [...(prevServiceRequest.assignedStaff || []), newStaff],
      };
    });
  };

  return (
    <div className="md:col-span-6">
      <FieldLabel name="Assigned Staff" />

      <div className="mb-4"></div>
      {serviceRequest?.assignedStaff?.length > 0 && (
        <div className="mb-4">
          {serviceRequest.assignedStaff.map((staff, index) => (
            <div
              key={index}
              className="mb-2 border rounded-lg font-regular border-[#ddecf3] flex items-center justify-between"
            >
              <div className="py-4 px-4 border-b-0 w-full border-r-[1px]">
                <div className="flex items-center gap-3">
                  <div className="bg-black rounded-full p-4"></div>
                  <p className="text-md">
                    {staff.staffName} {staff.staffSurname}
                  </p>
                </div>
              </div>
              <div
                className="flex items-center cursor-pointer"
                onClick={() =>
                  setServiceRequest((prevServiceRequest) => ({
                    ...prevServiceRequest,
                    assignedStaff: prevServiceRequest.assignedStaff.filter(
                      (s) => s.staffId !== staff.staffId
                    ),
                  }))
                }
              >
                <div className="font-medium border-[#ddecf3] py-4 px-7 flex items-center justify-center">
                  <BinIcon />
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      <input
        type="text"
        className="block w-full py-4 px-4 border border-[#ddecf3] rounded-lg text-sm outline-none mb-2"
        placeholder="Search staff"
        value={searchQuery}
        onChange={(e) => handleSearchChange(e.target.value)}
      />
      {loading ? (
        <p className="text-sm text-center text-gray-500">
          <ButtonLoader />
        </p>
      ) : staffs.length > 0 ? (
        staffs.map((staff) => (
          <div
            key={staff.id}
            className="mb-2 border rounded-lg font-regular border-[#ddecf3] flex items-center justify-between"
          >
            <div className="py-4 px-4 border-b-0 w-full border-r-[1px]">
              <p className="text-md">
                {staff.firstName} {staff.lastName}
              </p>
            </div>
            <div
              className="flex items-center cursor-pointer"
              onClick={() => handleAddStaff(staff)}
            >
              <div className="font-medium border-[#ddecf3] py-4 px-7 flex items-center justify-center">
                <PlusIcon />
              </div>
            </div>
          </div>
        ))
      ) : (
        <p className="text-sm text-gray-500 ms-auto">
          {searchQuery.length < 3
            ? "Enter at least 3 characters to search."
            : "No staff found"}
        </p>
      )}
    </div>
  );
}

export default AssignedStaff;
