import React from "react";
import { Bell, GraduationCap, Globe, HelpCircle, Blocks } from "lucide-react";
import { Link } from "react-router-dom";

export default function Navbar({ toggleSidebar }) {
  return (
    <nav className="flex items-center justify-between px-4 py-3 border-b bg-white shadow-sm">
      <div className="flex items-center space-x-6">
        <button onClick={toggleSidebar}>
          <Blocks className="h-6 w-6" />
          <span className="sr-only">Toggle Sidebar</span>
        </button>
        <Link to="/calender" className="flex items-center ">
          <img
            src="/assets/images/logo.png"
            alt="logodark"
            width={132}
            height={24}
          />
        </Link>
      </div>
      <div className="flex items-center space-x-2">
        <button variant="ghost" size="icon">
          <Globe className="h-5 w-6 text-primary" />
          <span className="sr-only">Language</span>
        </button>
        <button variant="ghost" size="icon">
          <Bell className="h-5 w-6 text-primary" />
          <span className="sr-only">Notifications</span>
        </button>
        <button variant="ghost" size="icon">
          <GraduationCap className="h-5 w-6 text-primary" />
          <span className="sr-only">Education</span>
        </button>
        <button variant="ghost" size="icon">
          <HelpCircle className="h-5 w-6 text-primary" />
          <span className="sr-only">Help</span>
        </button>
        <div>
          <div className="border mx-2 h-7 border-secondary opacity-50"></div>
        </div>
        <div className="flex space-x-2 items-center">
          <span className="text-sm font-medium text-gray-700 hidden sm:inline">
            <span className="text-sm font-medium text-gray-700">
              <Link to="/myprofile">My Account</Link>
            </span>
          </span>
          <div className="w-8 h-8 bg-orange-500 rounded-full flex items-center justify-center">
            <span className="text-xs font-medium text-white">CC</span>
          </div>
        </div>
      </div>
    </nav>
  );
}
