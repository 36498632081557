import ReactDOM from "react-dom";
import { AddNoteIcon, ImageIcon, UploadFileIcon } from "../../utils/Icons";
import { useState } from "react";
import AddNote from "./AddNote";

function Dropdown({ isOpen, position, onClose }) {
  const [isModalOpen, setModalOpen] = useState(false);

  const handleAddClick = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleDropdownClick = (e) => {
    e.stopPropagation(); // Prevent dropdown from closing when clicked
  };

  if (!isOpen) return null;

  const dropdownStyle = {
    top: `${position.top}px`,
    left: `${position.left - 100}px`,
    position: "fixed",
    zIndex: 50, // Lower z-index for dropdown
  };

  return ReactDOM.createPortal(
    <>
      <div
        className="mt-3 rounded-2xl absolute w-48 bg-white border-gray-100 border-[1px] shadow-lg py-2"
        style={dropdownStyle}
        onClick={handleDropdownClick}
      >
        <ul>
          <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
            <div className="flex items-center gap-4">
              <div className="rounded-lg bg-[#0075FF] flex justify-center items-center w-8 h-8">
                <AddNoteIcon />
              </div>
              <button onClick={handleAddClick}>Add note</button>
            </div>
          </li>
          <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
            <div className="flex items-center gap-4">
              <div className="rounded-lg bg-[#00AEAE] flex justify-center items-center w-8 h-8">
                <ImageIcon />
              </div>
              Upload Photo
            </div>
          </li>
          <li className="px-4 py-2 hover:bg-gray-100 cursor-pointer">
            <div className="flex items-center gap-4">
              <div className="rounded-lg bg-[#FFA800] flex justify-center items-center w-8 h-8">
                <UploadFileIcon />
              </div>
              Upload File
            </div>
          </li>
        </ul>
      </div>
      <AddNote isOpen={isModalOpen} onClose={handleCloseModal} />
    </>,
    document.body
  );
}

export default Dropdown;
