import React, { useCallback, useEffect, useState } from "react";
import StaffSearch from "./StaffSearch";
import ButtonLoader from "../Common/ButtonLoader";
import StaffSidebar from "./StaffSidebar";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { checkAuthAndRedirect } from "../Common/RefreshToken";

export default function LeftSide({
  staffs,
  setSelectedStaffId,
  selectedStaffId,
  setStaffs,
  setCreateStaff,
  createStaff,
  setActiveView
}) {
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const token = useSelector((state) => state.userData.user.accessToken);
  const loggedUserData = useSelector((state) => state.userData.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const fetchStaffs = useCallback(async () => {
    if (loading || !hasMore) return;

    setLoading(true);
    const token = await checkAuthAndRedirect(navigate, dispatch, loggedUserData);

    const isValidSearchQuery = searchQuery && searchQuery.length >= 3;
    const sanitizedQuery = isValidSearchQuery
      ? encodeURIComponent(searchQuery.replace(/[^a-zA-Z0-9 ]/g, ""))
      : "";

    const url = `${
      process.env.REACT_APP_API_URL
    }/api/v1/staff?Page=${page}&PageSize=10${
      sanitizedQuery ? `&Search=${sanitizedQuery}` : ""
    }`;

    const options = {
      method: "GET",
      headers: {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const response = await fetch(url, options);
      const data = await response.json();

      if (response.ok) {
        setStaffs((prevStaffs) =>
          page === 1 ? data.list : [...prevStaffs, ...data.list]
        );
        setHasMore(data.list.length > 0);
      } else {
        toast.error(data.detail || "Internal Server Error");
        setHasMore(false);
      }
    } catch (error) {
      toast.error("Internal Server Error");
      setHasMore(false);
    } finally {
      setLoading(false);
    }
    //eslint-disable-next-line
  }, [loading, hasMore, page, searchQuery, token]);

  useEffect(() => {
    fetchStaffs();
    // eslint-disable-next-line
  }, [searchQuery, page, token]);

  const handleScroll = (e) => {
    const { scrollTop, clientHeight, scrollHeight } = e.target;
    if (scrollHeight - scrollTop <= clientHeight + 100 && !loading && hasMore) {
      setPage((prevPage) => prevPage + 1);
    }
  };

  const handleSearchChange = (value) => {
    setSearchQuery(value);
    setPage(1); // Reset to first page on a new search
    setHasMore(true);
  };

  return (
    <div
      onScroll={handleScroll}
      className={`w-full lg:w-1/3 md:border-r border-[#ddecf3] overflow-y-auto scrollbar-thin scroll-smooth h-full ${
        selectedStaffId || createStaff ? "hidden lg:block" : ""
      }`}
    >
      <StaffSearch
        onSearchChange={handleSearchChange}
        searchTerm={searchQuery}
      />

      {loading && page === 1 ? (
        <div className="flex justify-center items-center h-full">
          <div className="w-full flex justify-center">
            <ButtonLoader />
          </div>
        </div>
      ) : (
        <StaffSidebar
          staffs={staffs}
          setSelectedStaffId={setSelectedStaffId}
          selectedStaffId={selectedStaffId}
          setCreateStaff={setCreateStaff}
          setActiveView={setActiveView}
        />
      )}
      {loading && page > 1 && (
        <div className="w-full flex justify-center">
          <ButtonLoader />
        </div>
      )}
    </div>
  );
}
