import React from "react";

function StaffSidebar({
  staffs,
  selectedStaffId,
  setSelectedStaffId,
  setCreateStaff,
  setActiveView
}) {
  const handleOnClick = (id) => {
    setSelectedStaffId(id);
    setCreateStaff(false);
    setActiveView("details");
  };

  return (
    <>
      <div className="font-semibold border-t border-b text-primary border-[#ddecf3] bg-[#F0F5F7] py-2  ps-4">
        Staff
      </div>
      <div className="mb-2 w-full">
        {staffs.map((staff) => {
          const initials = `${staff?.firstName[0] || ""}${
            staff?.lastName[0] || ""
          }`.toUpperCase();
          return (
            <div
              key={staff?.id}
              onClick={() => handleOnClick(staff?.id)}
              className={`flex items-center space-x-4 p-4 border-b cursor-pointer ${
                selectedStaffId === staff?.id ? "bg-[#ddecf3]" : ""
              }`}
            >
              <div
                className={`w-10 h-10 rounded-full flex items-center justify-center text-white bg-gray-400`}
              >
                {initials}
              </div>
              <div className="ms-5">
                <div className="font-medium">
                  {staff?.firstName} {staff?.lastName}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
}

export default StaffSidebar;
