import { useState } from "react";
import { X } from "lucide-react";

export default function UnavailableTime({ isOpen, onClose }) {
  const [formData, setFormData] = useState({
    startdate: "",
    enddate: "",
    totalhours: "",
    allday: false,
    type: "",
    description: "existing",
  });
  const [notifyCustomer, setNotifyCustomer] = useState(false);
  if (!isOpen) return null;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <div className="fixed z-50 inset-0 bg-black bg-opacity-50 flex items-center justify-end">
      <div className="bg-white rounded-lg p-6 w-full max-w-md h-screen overflow-y-auto shadow-lg">
        <div className="flex justify-between items-center mb-4">
          <div></div>
          <h2 className="text-xl text-primary">Unavailable Time</h2>
          <button onClick={onClose} className="text-gray-600">
            <X />
          </button>
        </div>
        <div className="space-y-4">
          <input
            type="text"
            name="startdate"
            value={formData.startdate}
            onChange={handleInputChange}
            className="pl-5 border w-full py-4 rounded-md"
            placeholder="Start Date"
            onFocus={(e) => (e.target.type = "date")}
            onBlur={(e) => {
              if (!formData.startdate) {
                e.target.type = "text";
              }
            }}
          />
          <input
            type="text"
            name="enddate"
            value={formData.enddate}
            onChange={handleInputChange}
            className="pl-5 border w-full py-4 rounded-md"
            placeholder="End Date"
            onFocus={(e) => (e.target.type = "date")}
            onBlur={(e) => {
              if (!formData.enddate) {
                e.target.type = "text";
              }
            }}
          />
          <input
            type="text"
            name="totalhours"
            value={formData.totalhours}
            onChange={handleInputChange}
            className="pl-5 border w-full py-4 rounded-md"
            placeholder="Total Hours"
            onFocus={(e) => (e.target.type = "time")}
            onBlur={(e) => {
              if (!formData.totalhours) {
                e.target.type = "text";
              }
            }}
          />
          <div className="flex items-center justify-between px-5 border w-full py-4 rounded-md">
            <span>All Day</span>
            <CustomSwitch
              isOn={notifyCustomer}
              onToggle={() => setNotifyCustomer(!notifyCustomer)}
            />
          </div>
          <select
            name="type"
            value={formData.type}
            onChange={handleInputChange}
            className="pl-5 border w-full py-4 rounded-md"
          >
            <option value="">Select Type</option>
          </select>
          <textarea
            name="description"
            value={formData.description}
            onChange={handleInputChange}
            placeholder="Description"
            className="pl-5 border w-full py-4 rounded-md"
          />
        </div>
        <div className="mt-6 border w-full py-4 bg-primary text-white border-none hover:bg-[#ddecf3] hover:text-primary rounded-md">
          <button className="flex items-center justify-center px-4  w-full">
            <div>Add</div>
          </button>
        </div>
      </div>
    </div>
  );
}

function CustomSwitch({ isOn, onToggle }) {
  return (
    <div
      onClick={onToggle}
      className={`w-10 h-6 flex items-center bg-gray-200 rounded-full p-1 cursor-pointer ${
        isOn ? "bg-primary" : "bg-secondary"
      }`}
    >
      <div
        className={`bg-white w-4 h-4 rounded-full shadow-md transform duration-300 ${
          isOn ? "translate-x-4" : ""
        }`}
      />
    </div>
  );
}
