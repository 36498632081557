import React, { useState, useEffect } from "react";

function PhoneNumberInput({
  userCreateRequest,
  setUserCreateRequest,
  countries,
}) {
  const [selectedDialCode, setSelectedDialCode] = useState("");
  const [localPhoneNumber, setLocalPhoneNumber] = useState("");

  const parsePhoneNumber = (phoneNumber = "", countries) => {
    for (const country of countries) {
      if (phoneNumber.startsWith(country.dialCode)) {
        return {
          dialCode: country.dialCode,
          localNumber: phoneNumber.replace(country.dialCode, ""),
        };
      }
    }
    return { dialCode: "", localNumber: phoneNumber };
  };

  useEffect(() => {
    const { dialCode, localNumber } = parsePhoneNumber(
      userCreateRequest?.phoneNumber || "",
      countries
    );
    setSelectedDialCode(dialCode);
    setLocalPhoneNumber(localNumber);
  }, [userCreateRequest?.phoneNumber, countries]);

  const handlePhoneNumberChange = (dialCode, phoneNumber) => {
    setUserCreateRequest((prevUserCreateRequest) => ({
      ...prevUserCreateRequest,
      phoneNumber: `${dialCode}${phoneNumber}`,
    }));
  };

  const handleDialCodeChange = (e) => {
    const newDialCode = e.target.value;
    setSelectedDialCode(newDialCode);
    handlePhoneNumberChange(newDialCode, localPhoneNumber);
  };

  const handleLocalPhoneNumberChange = (e) => {
    const newPhoneNumber = e.target.value;
    if (newPhoneNumber.length <= 10) {
      setLocalPhoneNumber(newPhoneNumber);
      handlePhoneNumberChange(selectedDialCode, newPhoneNumber);
    }
  };

  return (
    <div className="flex w-full mb-2 rounded-lg border-[1px] border-[#ddecf3] overflow-hidden">
      <select
        value={selectedDialCode}
        onChange={handleDialCodeChange}
        className="px-4 py-4 border-r-none md:border-r-[1px] outline-none border-[#ddecf3]"
      >
        {countries?.map((country) => (
          <option key={country?.id} value={country?.dialCode}>
            {country?.dialCode}
          </option>
        ))}
      </select>
      <input
        type="number"
        className="outline-none flex-1 border-none  px-4 py-4"
        placeholder="684045205"
        value={localPhoneNumber}
        maxLength={10}
        onChange={handleLocalPhoneNumberChange}
      />
    </div>
  );
}

export default PhoneNumberInput;
