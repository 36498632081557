import React, { useRef, useState } from "react";
import { Plus } from "lucide-react";
import Dropdown from "./Dropdown";
import {
  BinIcon,
  CheckCircleIcon,
  ClockIcon,
  DropdownIcon,
  EyeIcon,
  GreaterIcon,
  StaffIcon,
} from "../../utils/Icons";
import Modal from "./Modal";

function NewBooking() {
  const [isModalOpen, setModalOpen] = useState(false);

  const [selectedCategory, setSelectedCategory] = useState("Upcoming");
  const [activeView, setActiveView] = useState("booking");
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
  const dotsRef = useRef(null);
  const handleAddClick = () => {
    setModalOpen(true);
  };

  const handleDotsClick = () => {
    if (dotsRef.current) {
      const rect = dotsRef.current.getBoundingClientRect();
      setDropdownPosition({ top: rect.bottom, left: rect.left });
    }
    setDropdownOpen(!isDropdownOpen);
  };

  const handleDropdownClose = () => {
    setDropdownOpen(false);
  };

  const bookings = {
    Upcoming: [
      {
        id: 1,
        date: "04 March",
        time: "04:00",
        name: "Gerinzo Cibuku",
        service: "Filling",
        status: "Confirmed",
        price: "Lek 25",
      },
      {
        id: 2,
        date: "04 March",
        time: "01:00",
        name: "Rosie Spencer",
        service: "Filling",
        status: "Confirmed",
        price: "Lek 25",
      },
      {
        id: 3,
        date: "04 March",
        time: "01:00",
        name: "Rosie Spencer",
        service: "Filling",
        status: "UnConfirmed",
        price: "Lek 25",
      },
      {
        id: 4,
        date: "05 March",
        time: "01:00",
        name: "Rosie Spencer",
        service: "Filling",
        status: "Confirmed",
        price: "Lek 25",
      },
    ],
  };

  const handleBookingClick = (booking) => {
    setSelectedBooking(booking);
    setActiveView("booking");
  };

  return (
    <>
      <div className="w-full max-w-6xl mx-auto">
        <div className="mb-6">
          <div className="flex justify-between items-center">
            <div className="font-bold text-xl mb-3">Bookings</div>
            <button
              onClick={handleAddClick}
              className="font-medium mb-3 bg-[#ddecf3] hover:bg-primary hover:text-white text-primary flex items-center px-3 py-3 md:px-5 rounded-lg"
            >
              <Plus size={22} className="me-3" /> New Booking
            </button>
          </div>
          <div className="flex md:space-x-4 space-x-3 mt-3 md:mt-0">
            {["Upcoming", "Past", "Unconfirmed", "Cancelled", "New"].map(
              (category) => (
                <button
                  key={category}
                  onClick={() => {
                    setSelectedCategory(category);
                    setSelectedBooking(null);
                  }}
                  className={`pb-2 md:text-sm text-xs ${
                    selectedCategory === category
                      ? "text-primary border-b-2 font-bold border-primary"
                      : "text-black text-opacity-60"
                  }`}
                >
                  {category}
                </button>
              )
            )}
          </div>
        </div>

        <div className="rounded-lg bg-white border-[1px] border-[#ddecf3] shadow-lg">
          <div className="flex flex-col lg:flex-row">
            <div className="w-full lg:w-1/3 md:border-r-[1px]  border-[#ddecf3]">
              <div className="space-y-6">
                {selectedCategory === "Upcoming" &&
                bookings.Upcoming.length > 0 ? (
                  <div className="text-sm rounded-tl-lg  overflow-hidden">
                    {["04 March", "05 March"].map((date) => (
                      <div key={date}>
                        <div className=" py-3 md:pb-4  flex  items-center text-primary bg-[#F0F5F7] border-b border-[#ddecf3] font-medium">
                          <div className="px-4">{date}</div>
                        </div>
                        {bookings.Upcoming.filter(
                          (booking) => booking.date === date
                        ).map((booking) => (
                          <div
                            key={booking.id}
                            className="w-full px-4 py-4 md:py-6 border-b border-[#ddecf3] cursor-pointer"
                            onClick={() => handleBookingClick(booking)}
                          >
                            <div className="flex">
                              <div className="pe-4 border-r-[1px] border-[#ddecf3] text-sm text-black text-opacity-50">
                                {booking.time}
                                <br />
                                <span className="font-semibold text-black text-opacity-50">
                                  AM
                                </span>
                              </div>
                              <div className="flex justify-between items-center w-full">
                                <div className="ps-4">
                                  <h2 className="font-regular">
                                    {booking.name}
                                  </h2>
                                  <h2 className="text-sm text-black font-medium">
                                    {booking.service}
                                  </h2>
                                </div>
                                <div className="text-right">
                                  <h2
                                    className={`text-sm font-medium ${
                                      booking.status === "Confirmed"
                                        ? "text-[#55BAAE]"
                                        : booking.status === "Unconfirmed"
                                        ? "text-[#E6B400]"
                                        : "text-[#E6B400]"
                                    }`}
                                  >
                                    {booking.status}
                                  </h2>
                                  <h2 className="text-sm text-black text-opacity-60 font-semibold">
                                    {booking.price}
                                  </h2>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="w-full text-center py-10">
                    <h2 className="text-sm text-gray-500">
                      No data found for {selectedCategory} bookings.
                    </h2>
                  </div>
                )}
              </div>
            </div>
            <div className="min-h-screen w-full lg:w-2/3 bg-white rounded-xl md:rounded-r-xl">
              {selectedBooking ? (
                <div>
                  <div className="bg-[#F0F5F7] border-b border-[#ddecf3] md:rounded-tr-xl">
                    <div className="flex flex-wrap justify-center md:justify-between items-center">
                      <div className="flex items-center space-x-6 md:px-6 px-4 py-3">
                        <button
                          className={`font-medium  text-sm ${
                            activeView === "booking"
                              ? "text-primary font-bold"
                              : "text-gray-600"
                          }`}
                          onClick={() => setActiveView("booking")}
                        >
                          Booking
                        </button>
                        <button
                          className={`font-medium  text-sm ${
                            activeView === "forms"
                              ? "text-primary font-bold"
                              : "text-gray-600"
                          }`}
                          onClick={() => setActiveView("forms")}
                        >
                          Forms
                        </button>
                      </div>
                      <div>
                        <button
                          className={`font-medium md:px-6 px-4 py-3 text-sm ${
                            activeView === "edit_booking"
                              ? "text-primary font-bold"
                              : "text-gray-600"
                          }`}
                          onClick={() => setActiveView("edit_booking")}
                        >
                          Edit Booking
                        </button>
                      </div>
                      <div className="border-l border-[#ddecf3] p-3 md:w-[20%] w-full md:rounded-tr-lg text-white bg-[#55BAAE] relative">
                        <div
                          ref={dotsRef}
                          className="flex justify-center items-center  cursor-pointer"
                          onClick={handleDotsClick}
                        >
                          <div className="flex items-center gap-1">
                            <h2 className="font-medium text-white">
                              Confirmed
                            </h2>
                            <DropdownIcon size="10" color="#ffff" />
                          </div>
                        </div>
                      </div>
                      <Dropdown
                        isOpen={isDropdownOpen}
                        position={dropdownPosition}
                        onClose={handleDropdownClose}
                      />
                    </div>
                  </div>

                  {activeView === "booking" && (
                    <div className="my-6 md:px-6 px-4">
                      <div className="flex justify-between items-center flex-wrap md:flex-nowrap">
                        <div className="flex items-center gap-4">
                          <div className="bg-[#D9D9D9] rounded-full md:p-7 p-5"></div>
                          <div>
                            <h2 className="text-primary font-semibold md:text-md text-sm">
                              Gerinzo Cibuku
                            </h2>
                            <h2 className="font-medium text-black text-opacity-60 md:text-md text-sm">
                              355684045205
                            </h2>
                            <button className="text-black hover:text-primary md:text-md text-sm">
                              <u>View Customer</u>
                            </button>
                          </div>
                        </div>
                        <div className="w-full md:w-[190px] bg-[#F0F5F7] flex items-center justify-between border-[1px] border-[#ddecf3] rounded-lg mt-3 md:mt-0">
                          <div className="flex items-center  px-2 md:px-4 py-2 md:border-r md:border-[#ddecf3] ">
                            <div className="flex items-center gap-2 md:gap-3">
                              <CheckCircleIcon />
                              <div>
                                <h2 className="text-black text-opacity-60 font-medium text-xs md:text-sm">
                                  Status
                                </h2>
                                <h2 className="font-semibold text-[#55BAAE] text-xs md:text-sm">
                                  Confirmed
                                </h2>
                              </div>
                            </div>
                          </div>
                          <div className="px-2 md:px-4 py-2 ">
                            <DropdownIcon className="flex justify-center items-center " />
                          </div>
                        </div>
                      </div>

                      <div className="mt-5">
                        <div className="border-[1px] border-[#ddecf3] bg-[#F0F5F7] rounded-lg">
                          <div className="py-2 px-4 flex justify-between items-center">
                            <h2 className="font-medium text-sm ">Details</h2>
                            <h2 className="font-medium text-sm flex items-center gap-2">
                              View Invoice <GreaterIcon />{" "}
                            </h2>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col md:hidden justify-between items-center mt-6">
                        <div className="flex w-full justify-between">
                          <div className="rounded-lg border-[1px] border-[#ddecf3]">
                            <h2 className="text-center border-b border-[#ddecf3] px-5 py-2">
                              04:00 AM
                            </h2>
                            <h2 className="text-primary font-medium text-center bg-[#DDECF3] bg-opacity-50 px-5 py-1">
                              4th Mar
                            </h2>
                          </div>
                          <div className="ml-4">
                            <h2 className="text-primary mb-3 text-end">
                              Lek 25
                            </h2>
                            <div className="border-[1px] rounded-lg border-[#ddecf3] p-2">
                              <h2 className="font-medium text-center text-black text-opacity-50">
                                Not Paid
                              </h2>
                            </div>
                          </div>
                        </div>

                        <div className="flex-1 mt-6">
                          <h2 className="text-primary">Filling</h2>
                          <h2 className="font-medium">(Procedures)</h2>
                          <div className="flex items-center gap-3 mt-3">
                            <div className="bg-[#D9D9D9] rounded-full p-4"></div>
                            <h2 className="font-semibold text-sm">Gerinzo</h2>
                            <div className="flex items-center gap-3">
                              <ClockIcon size={25} />
                              <h2 className="text-black text-opacity-60 text-sm">
                                1 day 30 minutes, Staff Created Booking
                              </h2>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className=" justify-between gap-3 items-center mt-6 hidden md:flex">
                        <div className="rounded-lg border-[1px] border-[#ddecf3] ">
                          <h2 className="text-center border-b border-[#ddecf3] px-5 py-2">
                            04:00 AM
                          </h2>
                          <h2 className="text-primary font-medium text-center bg-[#DDECF3] bg-opacity-50 px-5 py-1">
                            4th Mar
                          </h2>
                        </div>
                        <div>
                          <h2 className="text-primary">Filling</h2>
                          <h2 className="font-medium">(Procedures)</h2>
                          <div className="flex items-center gap-3 mt-3">
                            <div className="bg-[#D9D9D9] rounded-full p-4"></div>
                            <h2 className="font-semibold md:text-md text-sm">
                              Gerinzo
                            </h2>
                            <div className="flex items-center gap-3">
                              <ClockIcon />
                              <h2 className="text-black text-opacity-60 md:text-md text-sm">
                                1 day 30 minutes, Staff Created Booking
                              </h2>
                            </div>
                          </div>
                        </div>
                        <div>
                          <h2 className="text-primary mb-3 text-end">Lek 25</h2>
                          <div className="border-[1px] rounded-lg border-[#ddecf3] p-2">
                            <h2 className="font-medium text-center text-black text-opacity-50">
                              Not Paid
                            </h2>
                          </div>
                        </div>
                      </div>

                      <div className="mt-10">
                        <div className="grid md:grid-cols-12 grid-cols-1 gap-5">
                          <div className="md:col-span-6">
                            <div className="border-[1px] border-[#ddecf3] bg-[#F0F5F7] rounded-lg">
                              <div className="py-2 px-4 flex justify-between items-center">
                                <h2 className="font-medium text-sm ">Notes</h2>
                              </div>
                            </div>
                            <h2 className="text-sm mt-3">Lorem Ipsum</h2>
                          </div>
                          <div className="md:col-span-6">
                            <div className="border-[1px] border-[#ddecf3] bg-[#F0F5F7] rounded-lg">
                              <div className="py-2 px-4 flex justify-between items-center">
                                <h2 className="font-medium text-sm ">
                                  Attachments
                                </h2>
                              </div>
                            </div>
                            <h2 className="text-sm mt-3">
                              This booking does not have any attachment files
                            </h2>
                          </div>
                        </div>
                      </div>
                      <div className="mt-5">
                        <div className="grid md:grid-cols-12 grid-cols-1 gap-5">
                          <div className="md:col-span-12">
                            <div className="border-[1px] border-[#ddecf3] bg-[#F0F5F7] rounded-lg">
                              <div className="py-2 px-4 flex justify-between items-center">
                                <h2 className="font-medium text-sm ">
                                  Messages
                                </h2>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {activeView === "forms" && (
                    <div className="my-6 md:px-6 px-4">
                      {" "}
                      <div className="mt-5">
                        <div className="border-[1px] border-[#ddecf3] bg-[#F0F5F7] rounded-lg">
                          <div className="py-2 px-4 flex justify-between items-center">
                            <h2 className="font-medium text-sm ">
                              From Template
                            </h2>
                            <div className="flex items-center gap-4">
                              <h2 className="font-medium text-sm">View</h2>
                              <h2 className="font-medium text-sm">Delete</h2>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="px-4 border-b border-[#ddecf3]">
                        <div className=" flex justify-between items-center my-6   ">
                          <div>
                            <h2 className="md:text-md text-sm">
                              Appointment Note
                            </h2>
                            <h2 className="font-medium md:text-md text-sm text-black text-opacity-60 mt-1">
                              (March 2nd 2024 - 04:30 PM)
                            </h2>
                          </div>
                          <div className="flex items-center gap-2">
                            <div className="rounded-full border-[1px] border-[#ddecf3] md:p-3 p-2">
                              <EyeIcon />
                            </div>
                            <div className="rounded-full border-[1px] border-[#ddecf3] md:p-3 p-2">
                              <BinIcon />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex justify-center items-center mt-5">
                        <button className="font-medium mb-3 bg-[#ddecf3] text-primary hover:bg-primary hover:text-white flex items-center px-5 py-3 rounded-md">
                          <Plus size={22} className="me-3" /> Add New Form
                        </button>
                      </div>
                    </div>
                  )}
                  {activeView === "edit_booking" && (
                    <div className="my-6 md:px-6 px-4">
                      <div className="border-[1px] border-[#ddecf3] bg-[#F0F5F7] rounded-lg">
                        <div className="py-2 px-4">
                          <h2 className="font-medium text-sm ">Edit booking</h2>
                        </div>
                      </div>
                      <div className="mt-5 flex justify-between items-center">
                        <div className="flex items-center gap-4">
                          <div className="bg-[#D9D9D9] rounded-full md:p-7 p-5"></div>
                          <div>
                            <h2 className="text-primary font-semibold md:text-md text-sm">
                              Gerinzo Cibuku
                            </h2>
                            <h2 className="font-medium text-black text-opacity-60 md:text-md text-sm">
                              355684045205
                            </h2>
                          </div>
                        </div>
                        <div>
                          <h2 className="text-primary mb-2 text-end">Lek 25</h2>
                          <div className="border-[1px] rounded-lg border-[#ddecf3] px-3 py-1">
                            <h2 className="font-medium text-center text-black text-opacity-50">
                              Not Paid
                            </h2>
                          </div>
                        </div>
                      </div>

                      <div className="mt-5">
                        <div className="border-[1px] border-[#ddecf3] bg-[#F0F5F7] rounded-lg">
                          <div className="py-2 px-4 ">
                            <h2 className="font-medium text-sm ">Summary</h2>
                          </div>
                        </div>
                      </div>
                      <div className="mt-5">
                        <div className="border-[1px] border-[#F3DDDD] bg-[#F3DDDD] bg-opacity-20 rounded-lg">
                          <div className="py-2 px-4 flex items-center gap-3 ">
                            <StaffIcon />
                            <div>
                              <h2 className="font-medium text-sm text-[#D3183A] ">
                                Staff is not available
                              </h2>
                              <h2 className="text-balck text-opacity-60 text-sm">
                                The chosen staff is already booked for the
                                selected time. Choose a different time or staff
                              </h2>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="grid md:grid-cols-12 grid-cols-1 gap-5 mt-5 mb-2">
                        <div className="md:col-span-6">
                          <div className="flex-1 py-2 px-4 border-[1px] rounded-lg border-[#ddecf3]">
                            <label
                              htmlFor="cost"
                              className="block text-xs text-black text-opacity-50 ps-1"
                            >
                              Select Service
                            </label>
                            <select className="outline-none">
                              <option value="Procedures">Filling</option>
                              <option value="Diagnostics">Diagnostics</option>
                              <option value="Treatments">Treatments</option>
                            </select>
                          </div>
                        </div>
                        <div className="md:col-span-6">
                          <div className="flex-1 py-2 px-4 border-[1px] rounded-lg border-[#ddecf3]">
                            <label
                              htmlFor="cost"
                              className="block text-xs text-black text-opacity-50 ps-1"
                            >
                              Select Staff
                            </label>
                            <select className="outline-none">
                              <option value="Procedures">Gerinzo</option>
                              <option value="Diagnostics">Diagnostics</option>
                              <option value="Treatments">Treatments</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="grid md:grid-cols-12 grid-cols-1 gap-5 mb-2 ">
                        <div className="md:col-span-6">
                          <div className="">
                            <div className="flex w-full max-w-md rounded-lg border border-[#ddecf3]">
                              <div className="flex-1 py-2 px-4 border-r-[1px] border-[#ddecf3]">
                                <label
                                  htmlFor="days"
                                  className="block text-xs text-black text-opacity-50"
                                >
                                  Days
                                </label>
                                <input
                                  type="number"
                                  id="days"
                                  defaultValue="20"
                                  className="block w-full border-none outline-none"
                                />
                              </div>
                              <div className="flex-1 py-2 px-4 border-r-[1px] border-[#ddecf3]">
                                <label
                                  htmlFor="hours"
                                  className="block text-xs text-black text-opacity-50"
                                >
                                  Hours
                                  <i className="mdi mdi-account-multiple-plus-outline:"></i>
                                </label>
                                <input
                                  type="text"
                                  id="hours"
                                  defaultValue="0"
                                  className="block w-full border-none outline-none"
                                />
                              </div>
                              <div className="flex-1 py-2 px-4">
                                <label
                                  htmlFor="minutes"
                                  className="block text-xs text-black text-opacity-50"
                                >
                                  Minutes
                                </label>
                                <input
                                  type="number"
                                  id="minutes"
                                  defaultValue="30"
                                  className="block w-full border-none outline-none"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="md:col-span-6">
                          <div className="">
                            <div className="flex w-full max-w-md rounded-lg border border-[#ddecf3]">
                              <div className="flex-1 py-2 px-4 border-r-[1px] border-[#ddecf3]">
                                <label
                                  htmlFor="price"
                                  className="block text-xs text-black text-opacity-50"
                                >
                                  Price
                                </label>
                                <input
                                  type="text"
                                  id="price"
                                  defaultValue="25€"
                                  className="block w-full border-none outline-none"
                                />
                              </div>
                              <div className="flex-1 py-2 px-4 border-r-[1px] border-[#ddecf3]">
                                <label
                                  htmlFor="discount"
                                  className="block text-xs text-black text-opacity-50"
                                >
                                  Discount
                                </label>
                                <input
                                  type="text"
                                  id="discount"
                                  defaultValue="0,00"
                                  className="block w-full border-none outline-none"
                                />
                              </div>
                              <div className="flex-1 py-2 px-4">
                                <label
                                  htmlFor="total"
                                  className="block text-xs text-black text-opacity-50"
                                >
                                  Total
                                </label>
                                <input
                                  type="text"
                                  id="total"
                                  defaultValue="25€"
                                  className="block w-full border-none outline-none"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="grid md:grid-cols-12 grid-cols-1 gap-5 mb-2">
                        <div className="md:col-span-6">
                          <div className="flex-1 py-2 px-4 border-[1px] rounded-lg border-[#ddecf3]">
                            <label
                              htmlFor="cost"
                              className="block text-xs text-black text-opacity-50 ps-1"
                            >
                              Date
                            </label>
                            <select className="outline-none">
                              <option value="Procedures">03/05/2024</option>
                              <option value="Diagnostics">06/12/2028</option>
                              <option value="Treatments">20/05/2024</option>
                            </select>
                          </div>
                        </div>
                        <div className="md:col-span-6">
                          <div className="flex-1 py-2 px-4 border-[1px] rounded-lg border-[#ddecf3]">
                            <label
                              htmlFor="cost"
                              className="block text-xs text-black text-opacity-50 ps-1"
                            >
                              Time
                            </label>
                            <select className="outline-none">
                              <option value="Procedures">01:00 PM</option>
                              <option value="Diagnostics">12:00 AM</option>
                              <option value="Treatments">3:00 PM</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="relative z-0 w-full group mb-2">
                        <textarea
                          id="email"
                          defaultValue="Note"
                          className="ps-4 block py-4 px-0 w-full text-sm text-black text-opacity-50 bg-transparent border border-[#ddecf3] appearance-none rounded-lg"
                          placeholder=" "
                          rows="4"
                        ></textarea>
                      </div>
                      <div className=" mb-2 border rounded-lg font-regular border-[#ddecf3] flex items-center justify-between">
                        <div className=" flex items-center">
                          <div className="font-medium border-[#ddecf3] px-6   flex  items-center  justify-center"></div>
                        </div>
                        <div className="border-[#ddecf3] w-full border-l-[1px]">
                          <div className="py-3 px-3 flex items-center">
                            <label className="relative inline-flex items-center cursor-pointer">
                              <input
                                type="checkbox"
                                className="sr-only peer"
                                value=""
                              />
                              <div className="group peer bg-white rounded-full duration-300 w-12 h-6 ring-2 ring-[#ddecf3] after:duration-300 after:bg-[#ddecf3] peer-checked:after:bg-primary peer-checked:ring-primary after:rounded-full after:absolute after:h-5 after:w-5 after:top-0.5 after:left-0.5 after:flex after:justify-center after:items-center peer-checked:after:translate-x-6 peer-hover:after:scale-95"></div>
                            </label>

                            <h2 className="ml-3 md:text-md text-sm">
                              Set service price as a starting from price
                            </h2>
                          </div>
                        </div>
                      </div>
                      <button className="w-full font-medium  bg-primary hover:bg-[#ddecf3] hover:text-black text-white flex justify-center items-center px-3 py-4 rounded-lg">
                        Save Changes
                      </button>
                    </div>
                  )}
                </div>
              ) : (
                <div className="md:px-0 px-4 min-h-screen w-full lg:w-2/3-50  rounded-r-xl flex justify-center items-center">
                  <div className="flex flex-col items-center text-center mb-6">
                    <img
                      width={50}
                      src="/assets/images/logodark.png"
                      alt="logo"
                    />
                    <h2 className="md:text-2xl text-xl font-semibold  text-primary my-3">
                      Bookings
                    </h2>
                    <p className="text-sm text-gray-600 md:w-2/3">
                      On the left sidebar you can see the list of confirmed and
                      unconfirmed bookings and on the right more in-depth
                      information about specific booking.
                    </p>
                    <button
                      onClick={handleAddClick}
                      className="font-medium mt-7 bg-[#ddecf3] hover:bg-primary hover:text-white text-primary flex items-center px-6 py-3 rounded-lg"
                    >
                      <Plus size={22} className="me-3" /> New Booking
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal isOpen={isModalOpen} onClose={() => setModalOpen(false)} />
    </>
  );
}

export default NewBooking;
