import React, { useEffect, useState } from "react";
import StaffTab from "./StaffTab";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import DeleteDialog from "./DeleteDialog";
import Tabs from "./Tabs";
import SettingsTab from "./SettingsTab";
import { useNavigate } from "react-router-dom";
import { checkAuthAndRedirect } from "../Common/RefreshToken";
function StaffDetails({
  activeView,
  setActiveView,
  onBack,
  selectedStaff,
  userCreateRequest,
  setUserCreateRequest,
  staffProfileCreateRequest,
  setStaffProfileCreateRequest,
}) {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const loggedUserData = useSelector((state) => state.userData.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const fetchStaff = async () => {
    setLoading(true);
    const token = await checkAuthAndRedirect(
      navigate,
      dispatch,
      loggedUserData
    );
    const url = `${process.env.REACT_APP_API_URL}/api/v1/staff/${selectedStaff}`;

    const options = {
      method: "GET",
      headers: {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const response = await fetch(url, options);
      const data = await response.json();

      if (response.ok) {
        setUserCreateRequest(data.userResponse);
        setStaffProfileCreateRequest(data.staffProfileResponse);
      } else {
        console.error("Error:", data);
        toast.error(data.detail || "Internal Server Error");
      }
    } catch (error) {
      console.error("Catch error:", error);
      toast.error("Internal Server Error");
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteConfirm = async () => {
    setLoading(true);
    const token = await checkAuthAndRedirect(
      navigate,
      dispatch,
      loggedUserData
    );
    try {
      const url = `${process.env.REACT_APP_API_URL}/api/v1/staff/${selectedStaff}`;
      const options = {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await fetch(url, options);

      if (response.ok) {
        toast.success("Staff deleted successfully");

        setIsDeleteDialogOpen(false);
        setLoading(false);
        window.location.reload();
      } else {
        const errorData = await response.json();
        toast.error(errorData.detail || "Failed to delete Staff");
        setLoading(false);
      }
    } catch (error) {
      toast.error("Error deleting customer");
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedStaff != null) {
      fetchStaff();
    }
    // eslint-disable-next-line
  }, [selectedStaff]);

  return (
    <>
      <div className="w-full mb-2">
        {/* Back Button - Only for mobile screens */}
        <div className="flex items-center">
          <button
            onClick={onBack}
            className="lg:hidden flex items-center px-3 mt-4 mb-4 text-primary hover:text-[#ddecf3] rounded-lg"
          >
            <span className="mr-2">←</span> Back
          </button>
          <h2 className="lg:hidden font-semibold text-black ml-3">
            {userCreateRequest?.firstName} {userCreateRequest?.lastName}
          </h2>
        </div>
        <Tabs
          selectedStaff={selectedStaff}
          activeView={activeView}
          setActiveView={setActiveView}
          setIsDeleteDialogOpen={setIsDeleteDialogOpen}
        />

        {activeView === "details" ? (
          <StaffTab
            userCreateRequest={userCreateRequest}
            setUserCreateRequest={setUserCreateRequest}
            staffProfileCreateRequest={staffProfileCreateRequest}
            setStaffProfileCreateRequest={setStaffProfileCreateRequest}
            selectedStaff={selectedStaff}
          />
        ) : (
          <SettingsTab
            userCreateRequest={userCreateRequest}
            setUserCreateRequest={setUserCreateRequest}
            staffProfileCreateRequest={staffProfileCreateRequest}
            setStaffProfileCreateRequest={setStaffProfileCreateRequest}
          />
        )}
      </div>
      {isDeleteDialogOpen && (
        <DeleteDialog
          isOpen={isDeleteDialogOpen}
          onClose={() => setIsDeleteDialogOpen(false)}
          onDelete={handleDeleteConfirm}
          loading={loading}
        />
      )}
    </>
  );
}

export default StaffDetails;
