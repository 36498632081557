import React from "react";
import Layout from "../Components/Common/Layout";
import CustomerList from "../Components/Customer/CustomerList";

function Customer() {
  return (
    <>
      <Layout>
        <CustomerList />
      </Layout>
    </>
  );
}

export default Customer;
