import React, { useCallback, useEffect, useState } from "react";
import CustomerSidebar from "./CustomerSidebar";
import CustomerSearch from "./CustomerSearch";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import ButtonLoader from "../Common/ButtonLoader";
import { checkAuthAndRedirect } from "../Common/RefreshToken";
import { useNavigate } from "react-router-dom";

export default function LeftSide({
  customers,
  setSelectedCustomerId,
  selectedCustomerId,
  setCustomers,
  setCreateCustomer,
  createCustomer,
  setCustomersTotal,
  setActiveView,
}) {
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const loggedUserData = useSelector((state) => state.userData.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const fetchCustomers = useCallback(async () => {
    if (loading || !hasMore) return;

    setLoading(true);

    const token = await checkAuthAndRedirect(
      navigate,
      dispatch,
      loggedUserData
    );

    const isValidSearchQuery = searchQuery && searchQuery.length >= 3;
    const sanitizedQuery = isValidSearchQuery
      ? encodeURIComponent(searchQuery.replace(/[^a-zA-Z0-9 ]/g, ""))
      : "";

    const url = `${
      process.env.REACT_APP_API_URL
    }/api/v1/customers?Page=${page}&PageSize=10${
      sanitizedQuery ? `&Search=${sanitizedQuery}` : ""
    }`;

    const options = {
      method: "GET",
      headers: {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const response = await fetch(url, options);
      const data = await response.json();

      if (response.ok) {
        setCustomersTotal(data.totalItems);
        setCustomers((prevCustomers) =>
          page === 1 ? data.list : [...prevCustomers, ...data.list]
        );
        setHasMore(data.list.length > 0);
      } else {
        toast.error(data.details || "Internal Server Error");
        setHasMore(false);
      }
    } catch (error) {
      console.error("Catch error:", error);
      setHasMore(false);
    } finally {
      setLoading(false);
    }
       // eslint-disable-next-line
  }, [loading, hasMore, page, searchQuery]);

  useEffect(() => {
    fetchCustomers();
    // eslint-disable-next-line
  }, [searchQuery, page]);

  const handleScroll = (e) => {
    const { scrollTop, clientHeight, scrollHeight } = e.target;
    if (scrollHeight - scrollTop <= clientHeight + 100 && !loading && hasMore) {
      setPage((prevPage) => prevPage + 1);  
    }
  };

  const handleSearchChange = (value) => {
    setSearchQuery(value);
    setPage(1);
    setHasMore(true);
  };

  const handleCustomerCreate = (newCustomer) => {
    setCustomers((prevCustomers) => [newCustomer, ...prevCustomers]);
    setCreateCustomer(false);
    toast.success("Customer created successfully!");
  };

  return (
    <div
      onScroll={handleScroll}
      className={`w-full lg:w-1/3 md:border-r border-[#ddecf3] overflow-y-auto scrollbar-thin h-full scroll-smooth ${
        selectedCustomerId || createCustomer ? "hidden lg:block" : ""
      }`}
    >
      <CustomerSearch
        onSearchChange={handleSearchChange}
        searchTerm={searchQuery}
      />

      {loading && page === 1 ? (
        <div className="flex justify-center items-center h-full">
          <ButtonLoader />
        </div>
      ) : (
        <CustomerSidebar
          customers={customers}
          onCustomerSelect={setSelectedCustomerId}
          selectedCustomerId={selectedCustomerId}
          setCreateCustomer={setCreateCustomer}
          setActiveView={setActiveView}
          handleCustomerCreate={handleCustomerCreate}
        />
      )}

      {loading && page > 1 && (
        <div className="w-full flex justify-center">
          <ButtonLoader />
        </div>
      )}
    </div>
  );
}
