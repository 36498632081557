import React from "react";
import { SearchIcon } from "../../utils/Icons";

function CustomerSearch({ onSearchChange, searchTerm }) {
  return (
    <div className="flex items-center border-b bg-white rounded-lg border-l border-secondary p-5 gap-2 sticky top-0 z-20">
      <SearchIcon className="h-5 w-5 text-secondary" />
      <input
        type="search"
        placeholder="Search for customer"
        className="w-full bg-transparent outline-none"
        value={searchTerm}
        onChange={(e) => onSearchChange(e.target.value)}
      />
    </div>
  );
}

export default CustomerSearch;
