import React from 'react'
import Topbar from '../Components/Common/Topbar'
import MyProfile from '../Components/Settings/MyProfile'

function Setting() {
  return (
    <div>
      <Topbar/>
      <MyProfile/>
    </div>
  )
}

export default Setting
