export const fetchLanguage = async (token) => {
  const languageUrl = `${process.env.REACT_APP_API_URL}/api/v1/standards/languages`;

  try {
    const response = await fetch(languageUrl, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      redirect: "follow",
    });

    const data = await response.json();

    if (response.ok) {
      return data;
    } else {
      console.error("Language Error:", data);
      return null;
    }
  } catch (error) {
    console.error("Fetch Language Error:", error);
    return null;
  }
};
