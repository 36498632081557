import { CheckIcon, Plus } from "lucide-react";
import React, { useState } from "react";
import ButtonLoader from "../Common/ButtonLoader";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { checkAuthAndRedirect } from "../Common/RefreshToken";
import { useNavigate } from "react-router-dom";

export default function TopActions({
  services,
  createService,
  setCreateService,
  selectedServiceId,
  setSelectedServiceId,
  serviceRequest,
  setServiceRequest,
  setActiveView
}) {
  const [loading, setLoading] = useState(false);
  const loggedUserData = useSelector((state) => state.userData.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const validateService = () => {
    let isValid = true;

    if (!serviceRequest.name || serviceRequest.name.trim() === "") {
      toast.error("Name is required.");
      isValid = false;
      return;
    }

    if (!serviceRequest.duration || serviceRequest.duration.trim() === "") {
      toast.error("Duration is required.");
      isValid = false;
      return isValid;
    }

    if (!serviceRequest.price === "") {
      toast.error("Price is required.");
      isValid = false;
      return;
    }

    if (!serviceRequest.categoryId || serviceRequest.categoryId.trim() === "") {
      toast.error("Category is required.");
      isValid = false;
      return;
    }

    return isValid;
  };
  const handleCreateService = async (event) => {
    event.preventDefault();
    setLoading(true);
    const token = await checkAuthAndRedirect(navigate, dispatch, loggedUserData);
  
    if (!validateService()) {
      setLoading(false);
      return;
    }
  
    // Ensure assignedStaff is an array of IDs
    const requestPayload = {
      ...serviceRequest,
      assignedStaff: serviceRequest?.assignedStaff?.map((staff) => staff?.staffId),
    };
  
    const url = `${process.env.REACT_APP_API_URL}/api/v1/services`;
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(requestPayload),
    };
  
    try {
      const response = await fetch(url, options);
      const data = await response.json();
      if (response.ok) {
        toast.success("Service Created Successfully!");
        setSelectedServiceId(data.id);
        setCreateService(false);
      } else {
        toast.error(data.detail || "Please validate the Fields");
      }
    } catch (error) {
      console.error("Catch error:", error);
      toast.error("Internal Server Error");
    } finally {
      setLoading(false);
    }
  };
  
  const handleUpdateService = async (event) => {
    event.preventDefault();
    setLoading(true);
    const token = await checkAuthAndRedirect(navigate, dispatch, loggedUserData);
  
    if (!validateService()) {
      setLoading(false);
      return;
    }
  
    // Transform assignedStaff to an array of IDs
    const updatedUserRequest = {
      ...serviceRequest,
      id: selectedServiceId,
      assignedStaff: serviceRequest?.assignedStaff?.map((staff) => staff?.staffId),
    };
  
    const url = `${process.env.REACT_APP_API_URL}/api/v1/services`;
    const options = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(updatedUserRequest),
    };
  
    try {
      const response = await fetch(url, options);
      if (response.ok) {
        toast.success("Services Updated Successfully!");
      } else {
        const errorData = await response.json();
        toast.error(errorData.detail || "Please validate the Fields");
      }
    } catch (error) {
      console.error("Catch error:", error);
      toast.error("Internal Server Error");
    } finally {
      setLoading(false);
    }
  };
  

  const handleOnCreateServiceClick = () => {
    setCreateService(true);
    setSelectedServiceId(null);
    setActiveView("details");
    setServiceRequest({
      name: "",
      description: "",
      duration: "",
      price: "",
      bookOnline: "",
      categoryId: "",
      assignedStaff: [],
    });
  };

  return (
    <div className="flex justify-between items-center mb-6">
      <div className="flex items-center">
        <span className="text-sm font-normal bg-secondary text-primary rounded px-3 py-1 mr-2">
          {services.length}
        </span>
        <h2 className="font-bold text-xl">Services</h2>
      </div>
      <div className="flex gap-3">
        {createService ? (
          <button
            onClick={handleCreateService}
            disabled={loading}
            className="flex items-center gap-2 justify-center font-medium text-white bg-primary md:px-5 px-4 md:py-3 py-2 rounded-lg"
          >
            {loading ? (
              <div className="w-full flex justify-center">
                <ButtonLoader />
              </div>
            ) : (
              <>
                <CheckIcon size={16} color="#ffff" />
                <h2>Save</h2>
              </>
            )}
          </button>
        ) : (
          ""
        )}

        {selectedServiceId ? (
          <button
            onClick={handleUpdateService}
            className="flex items-center gap-2 justify-center font-medium text-white bg-primary md:px-5 px-4 md:py-3 py-2 rounded-lg"
          >
            {loading ? (
              <div className="w-full flex justify-center">
                <ButtonLoader />
              </div>
            ) : (
              <>
                <CheckIcon size={16} color="#ffff" />
                <h2>Save</h2>
              </>
            )}
          </button>
        ) : (
          ""
        )}

        <button
          onClick={handleOnCreateServiceClick}
          className="flex items-center space-x-2 font-medium text-primary hover:bg-primary hover:text-white bg-secondary md:px-4 px-2 py-2 rounded-lg"
        >
          <Plus size={22} />
          <h2 className="hidden md:block">New Service</h2>
        </button>
      </div>
    </div>
  );
}
