import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { checkAuthAndRedirect } from "../Common/RefreshToken";
import moment from "moment";
import ButtonLoader from "../Common/ButtonLoader";

export default function AuditTab({ selectedCustomer }) {
  const [audit, setAudit] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const loggedUserData = useSelector((state) => state.userData.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const fetchAudit = useCallback(async () => {
    if (loading || !hasMore) return;

    setLoading(true);

    const token = await checkAuthAndRedirect(
      navigate,
      dispatch,
      loggedUserData
    );

    const url = `${process.env.REACT_APP_API_URL}/api/v1/customers/history?CustomerId=${selectedCustomer}&Page=${page}&PageSize=10`;

    const options = {
      method: "GET",
      headers: {
        "Content-Type": "text/plain",
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const response = await fetch(url, options);
      const data = await response.json();

      if (response.ok) {
        setAudit((prevAudit) =>
          page === 1 ? data.list : [...prevAudit, ...data.list]
        );
        setHasMore(data.list.length > 0);
      } else {
        toast.error(data.detail || "Internal Server Error");
        setHasMore(false);
      }
    } catch (error) {
      console.error("Catch error:", error);
      setHasMore(false);
    } finally {
      setLoading(false);
    }
  }, [
    loading,
    hasMore,
    page,
    selectedCustomer,
    navigate,
    dispatch,
    loggedUserData,
  ]);

  useEffect(() => {
    if (selectedCustomer) {
      fetchAudit();
    }
    //eslint-disable-next-line
  }, [page, selectedCustomer]);

  const handleScroll = (e) => {
    const { scrollTop, clientHeight, scrollHeight } = e.target;
    if (scrollHeight - scrollTop <= clientHeight + 100 && !loading && hasMore) {
      setPage((prevPage) => prevPage + 1); // Increment page when scrolled to bottom
    }
  };

  const formatMessage = (message) => {
    if (!message) return "";
    const lines = message.split("\n");
    return lines.map((line, index) => (
      <React.Fragment key={index}>
        {line}
        {index < lines.length - 1 && <br />}
      </React.Fragment>
    ));
  };

  return (
    <div
      onScroll={handleScroll}
      className="min-h-[50vh] py-8 px-5 grid grid-cols-1 md:grid-cols-1 gap-3 overflow-y-auto custom-scrollbar"
    >
      {audit?.length === 0 && !loading && (
        <div className="w-full flex justify-center py-3 text-gray-500">
          <p>No Record Found</p>
        </div>
      )}

      {audit?.map((item, index) => (
        <div
          key={index}
          className="w-full flex items-center border-[#ddecf3] border rounded-lg"
        >
          <div className="py-2 px-4">
            <h2>{formatMessage(item?.message)}</h2>
            <p className="text-gray-400">
              {item?.dateTime
                ? moment(item.dateTime).format("DD MMM, YYYY hh:mm A")
                : ""}
            </p>
          </div>
        </div>
      ))}

      {loading && page === 1 && (
        <div className="w-full flex justify-center">
          <div className="spinner-border" role="status">
            <span className="sr-only">
              <ButtonLoader />
            </span>
          </div>
        </div>
      )}

      {loading && page > 1 && (
        <div className="w-full flex justify-center py-3">
          <span>
            <ButtonLoader />
          </span>
        </div>
      )}
    </div>
  );
}
