import React, { useEffect, useState } from "react";
import ServiceSearch from "./ServiceSearch";
import ServiceSidebar from "./ServiceSidebar";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import ButtonLoader from "../Common/ButtonLoader";
import { checkAuthAndRedirect } from "../Common/RefreshToken";
import { useNavigate } from "react-router-dom";

function LeftSide({
  services,
  setServices,
  selectedServiceId,
  setSelectedServiceId,
  setCreateService,
  createService,
}) {
  const [loading, setLoading] = useState(false);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const loggedUserData = useSelector((state) => state.userData.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const fetchServices = async () => {
    setLoading(true);
    const token = await checkAuthAndRedirect(navigate, dispatch, loggedUserData);
    const url = `${process.env.REACT_APP_API_URL}/api/v1/services/grouped`;
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const response = await fetch(url, options);
      const data = await response.json();

      if (response.ok) {
        setServices(data.serviceCategories || []);
        setFilteredCategories(data.serviceCategories || []);
      } else {
        toast.error(data.detail || "Failed to fetch services.");
      }
    } catch (error) {
      toast.error("Error fetching services.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchServices();
    // eslint-disable-next-line
  }, []);

  const handleSearchChange = (value) => {
    setSearchQuery(value);

    const lowerQuery = value.toLowerCase();
    const filtered = services.map((category) => {
      const filteredServices = category?.services?.filter((service) =>
        service?.name?.toLowerCase().includes(lowerQuery)
      );
    
      return {
        ...category,
        services: filteredServices,
        isVisible:
          filteredServices.length > 0 ||
          category?.name.toLowerCase().includes(lowerQuery),
      };
    });
    

    setFilteredCategories(filtered.filter((category) => category.isVisible));
  };
  

  return (
    <div
      className={`w-full lg:w-1/3 md:border-r border-[#ddecf3] overflow-y-auto scrollbar-thin scroll-smooth h-full ${
        selectedServiceId || createService ? "hidden lg:block" : ""
      }`}
    >
      <ServiceSearch
        searchTerm={searchQuery}
        onSearchChange={handleSearchChange}
      />
      {loading ? (
        <div className="flex justify-center items-center h-full">
          <ButtonLoader />
        </div>
      ) : (
        <ServiceSidebar
          services={filteredCategories}
          onSelectService={setSelectedServiceId}
          setCreateService={setCreateService}
        />
      )}
    </div>
  );
}

export default LeftSide;
