import React from "react";
import FieldLabel from "../Common/FieldLabel";

export default function SettingsTab({
  userCreateRequest,
  setUserUpdateRequest,
  staffProfileCreateRequest,
  setStaffProfileCreateRequest,
}) {
  const handleUserCreateRequestChange = (field, value) => {
    setStaffProfileCreateRequest((prevstaffProfileCreateRequest) => ({
      ...prevstaffProfileCreateRequest,
      [field]: value,
    }));
  };

  const handleSelectChange = (e) => {
    const value = e.target.value === "true"; 
    handleUserCreateRequestChange("staffIsAlsoManager", value);
  };

  return (
    <div className="mb-10 px-5 grid grid-cols-1 md:grid-cols-12 md:gap-5">
      <div className="md:col-span-6">
        <FieldLabel name="Staff Login & Permission" />
        <div className="mb-2 flex-1 py-2 px-4 border-[1px] rounded-lg border-[#ddecf3]">
          <label
            htmlFor="type"
            className="block text-xs text-black text-opacity-50 ps-1"
          >
            User type
          </label>
          <select
            className="outline-none w-full"
            value={staffProfileCreateRequest.staffIsAlsoManager.toString()}
            onChange={handleSelectChange}
          >
            <option value="true">Manager</option>
            <option value="false">Staff</option>
          </select>
        </div>
        <div className="mb-2">
          <div className="rounded-lg px-4 border-[#ddecf3] border-[1px] py-2">
            <div className="w-full">
              <label
                htmlFor="email"
                className="text-xs font-medium text-black "
              >
                Email Address
              </label>

              <input
                type="email"
                id="email"
                disabled
                value={userCreateRequest.email || ""}
                className="block w-full text-sm outline-none bg-transparent text-black"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="md:col-span-6">
        <FieldLabel name={"Bookings"} />
        <div className="space-y-4 my-5">
          <div className="flex items-center gap-3">
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                className="sr-only peer"
                checked={staffProfileCreateRequest.acceptOnlineBooking}
                onChange={() =>
                  handleUserCreateRequestChange(
                    "acceptOnlineBooking",
                    !staffProfileCreateRequest.acceptOnlineBooking
                  )
                }
              />
              <div className="group peer bg-white rounded-full duration-300 w-10 h-5 ring-2 ring-[#ddecf3] after:duration-300 after:bg-[#ddecf3] peer-checked:after:bg-primary peer-checked:ring-primary after:rounded-full after:absolute after:h-4 after:w-4 after:top-0.5 after:left-0.5 after:flex after:justify-center after:items-center peer-checked:after:translate-x-5 peer-hover:after:scale-95"></div>
            </label>

            <h2 className="md:text-md text-sm">Accept Online Bookings</h2>
          </div>
          <div className="flex items-center gap-3">
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                className="sr-only peer"
                checked={staffProfileCreateRequest.showCalendarInPortal}
                onChange={() =>
                  handleUserCreateRequestChange(
                    "showCalendarInPortal",
                    !staffProfileCreateRequest.showCalendarInPortal
                  )
                }
              />
              <div className="group peer bg-white rounded-full duration-300 w-10 h-5 ring-2 ring-[#ddecf3] after:duration-300 after:bg-[#ddecf3] peer-checked:after:bg-primary peer-checked:ring-primary after:rounded-full after:absolute after:h-4 after:w-4 after:top-0.5 after:left-0.5 after:flex after:justify-center after:items-center peer-checked:after:translate-x-5 peer-hover:after:scale-95"></div>
            </label>

            <h2 className="md:text-md text-sm">Show Calendar in Portal</h2>
          </div>
          <div className="flex items-center gap-3">
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                className="sr-only peer"
                checked={staffProfileCreateRequest.showInStaffList}
                onChange={() =>
                  handleUserCreateRequestChange(
                    "showInStaffList",
                    !staffProfileCreateRequest.showInStaffList
                  )
                }
              />
              <div className="group peer bg-white rounded-full duration-300 w-10 h-5 ring-2 ring-[#ddecf3] after:duration-300 after:bg-[#ddecf3] peer-checked:after:bg-primary peer-checked:ring-primary after:rounded-full after:absolute after:h-4 after:w-4 after:top-0.5 after:left-0.5 after:flex after:justify-center after:items-center peer-checked:after:translate-x-5 peer-hover:after:scale-95"></div>
            </label>

            <h2 className="md:text-md text-sm">Show In Staff List</h2>
          </div>
        </div>
      </div>
    </div>
  );
}
