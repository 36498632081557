import React, { useEffect, useState } from "react";
import FieldLabel from "../Common/FieldLabel";
import { useSelector } from "react-redux";
function DetailTab({
  day,
  month,
  year,
  setDay,
  setMonth,
  setYear,
  profile,
  setProfile,
  address,
  setAddress,
  statistics,
}) {
  const handleProfileChange = (field, value) => {
    setProfile((prevProfile) => ({
      ...prevProfile,
      [field]: value,
    }));
  };

  const handleAddressChange = (field, value) => {
    setAddress((prevAddress) => ({
      ...prevAddress,
      [field]: value,
    }));
  };

  useEffect(() => {
    const handleChangeDate = () => {
      if (day && month && year) {
        const formattedDate = `${year}-${month.padStart(2, "0")}-${day.padStart(
          2,
          "0"
        )}`;
        setProfile((prevProfile) => ({
          ...prevProfile,
          birthdate: formattedDate,
        }));
      }
    };

    handleChangeDate();
    //eslint-disable-next-line
  }, [day, month, year]);

  const countries = useSelector((state) => state.countryData.country);
  const languages = useSelector((state) => state.languageData.language);
  // Function to extract dial code and local number
  const parsePhoneNumber = (phoneNumber = "", countries) => {
    for (const country of countries) {
      if (phoneNumber.startsWith(country.dialCode)) {
        return {
          dialCode: country.dialCode,
          localNumber: phoneNumber.replace(country.dialCode, ""),
        };
      }
    }
    // Default to empty dialCode and the whole phoneNumber as localNumber
    return { dialCode: "", localNumber: phoneNumber };
  };

  // Parse initial values
  const { dialCode: initialDialCode, localNumber: initialPhoneNumber } =
    parsePhoneNumber(profile?.phoneNumber || "", countries);

  const [selectedDialCode, setSelectedDialCode] = useState(initialDialCode);
  const [localPhoneNumber, setLocalPhoneNumber] = useState(initialPhoneNumber);

  // Update phone number in state
  const handlePhoneNumberChange = (dialCode, phoneNumber) => {
    setProfile((prevUserCreateRequest) => ({
      ...prevUserCreateRequest,
      phoneNumber: `${dialCode}${phoneNumber}`,
    }));
  };

  const handleDialCodeChange = (e) => {
    const newDialCode = e.target.value;
    setSelectedDialCode(newDialCode);
    handlePhoneNumberChange(newDialCode, localPhoneNumber);
  };

  const handleLocalPhoneNumberChange = (e) => {
    const newPhoneNumber = e.target.value;
    if (newPhoneNumber.length <= 10) {
      setLocalPhoneNumber(newPhoneNumber);
      handlePhoneNumberChange(selectedDialCode, newPhoneNumber);
    }
  };

  useEffect(() => {
    const setPhoneNumber = () => {
      const { dialCode, localNumber } = parsePhoneNumber(
        profile?.phoneNumber || "",
        countries
      );
      setSelectedDialCode(dialCode);
      setLocalPhoneNumber(localNumber);
    };
    setPhoneNumber();
  }, [profile.phoneNumber, countries]);

  return (
    <div className="px-5 grid grid-cols-1 md:grid-cols-12 gap-5 pb-5">
      <div className="md:col-span-6">
        <FieldLabel name="Contact Details" />
        <div className="mb-2">
          <div className="flex w-full max-w-md rounded-lg border border-[#ddecf3]">
            <div className="flex-1 py-2 px-4 border-r-[1px] border-[#ddecf3]">
              <label
                htmlFor="first_name"
                className="block text-xs text-black text-opacity-50"
              >
                First Name
              </label>
              <input
                type="text"
                id="first_name"
                value={profile?.firstName}
                onChange={(e) =>
                  handleProfileChange("firstName", e.target.value)
                }
                className="block w-full border-none outline-none"
              />
            </div>
            <div className="flex-1 py-2 px-4 ">
              <label
                htmlFor="last_name"
                className="block text-xs text-black text-opacity-50"
              >
                Last Name
              </label>
              <input
                type="text"
                id="last_name"
                value={profile?.lastName}
                onChange={(e) =>
                  handleProfileChange("lastName", e.target.value)
                }
                className="block w-full border-none outline-none"
              />
            </div>
          </div>
        </div>
        <div className="flex w-full mb-2 rounded-lg border-[1px] border-[#ddecf3] overflow-hidden">
          <select
            value={selectedDialCode}
            onChange={handleDialCodeChange}
            className="px-4 py-4 border-r-none md:border-r-[1px] outline-none border-[#ddecf3]"
          >
            {countries?.map((country) => (
              <option key={country?.id} value={country?.dialCode}>
                {country?.dialCode}
              </option>
            ))}
          </select>
          <input
            type="number"
            className="outline-none flex-1 border-none  px-4 py-4"
            placeholder="684045205"
            value={localPhoneNumber}
            maxLength={10}
            onChange={(e) => {
              const value = e.target.value;
              if (value.length <= 10) {
                handleLocalPhoneNumberChange(e);
              }
            }}
          />
        </div>
        <div className="mb-2">
          <div className="rounded-lg px-4 border-[#ddecf3] border-[1px] py-2">
            <div className="w-full">
              <label
                htmlFor="name"
                className="text-xs font-medium text-black text-opacity-50 "
              >
                Email Address
              </label>

              <input
                type="email"
                id="name"
                className="block w-full outline-none bg-transparent text-black"
                value={profile?.email}
                onChange={(e) => handleProfileChange("email", e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="mb-2 flex-1 py-2 px-4 border-[1px] rounded-lg border-[#ddecf3]">
          <label
            htmlFor="cost"
            className="block text-xs text-black text-opacity-50 ps-1"
          >
            Language
          </label>
          <select className="block w-full outline-none bg-transparent text-black">
            {languages?.map((language) => (
              <option key={language?.id} value={language?.name}>
                {language?.name}
              </option>
            ))}
          </select>
        </div>
        <FieldLabel name="Address" />
        <div className="mb-2">
          <div className="rounded-lg px-4 border-[#ddecf3] border-[1px] py-2">
            <div className="w-full">
              <label
                htmlFor="address"
                className="text-xs font-medium text-black text-opacity-50"
              >
                Address
              </label>

              <input
                type="text"
                id="address"
                className="block w-full outline-none bg-transparent text-black"
                value={address?.addressLine}
                onChange={(e) =>
                  handleAddressChange("addressLine", e.target.value)
                }
              />
            </div>
          </div>
        </div>
        <div className="mb-2">
          <div className="rounded-lg px-4 border-[#ddecf3] border-[1px] py-2">
            <div className="w-full">
              <label
                htmlFor="city"
                className="text-xs font-medium text-black text-opacity-50"
              >
                Town/City
              </label>

              <input
                type="text"
                id="city"
                className="block w-full outline-none bg-transparent text-black"
                value={address?.city}
                onChange={(e) => handleAddressChange("city", e.target.value)}
                name="city"
              />
            </div>
          </div>
        </div>
        <div className="mb-2">
          <div className="rounded-lg px-4 border-[#ddecf3] border-[1px] py-2">
            <div className="w-full">
              <label
                htmlFor="zip"
                className="text-xs font-medium text-black text-opacity-50"
              >
                Postcode
              </label>

              <input
                type="number"
                id="zip"
                className="block w-full outline-none bg-transparent text-black"
                value={address?.zip}
                onChange={(e) => handleAddressChange("zip", e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="mb-2">
          <div className="rounded-lg px-4 border-[#ddecf3] border-[1px] py-2">
            <div className="w-full">
              <label
                htmlFor="countrycode"
                className="text-xs font-medium text-black text-opacity-50"
              >
                Country
              </label>
              <select
                name=""
                id="countrycode"
                value={address?.countryCode}
                onChange={(e) => {
                  const value = e.target.value;
                  if (value.length <= 2) {
                    handleAddressChange("countryCode", value);
                  }
                }}
                className="w-full block outline-none bg-transparent text-black"
                required
              >
                <option value="" disabled selected>Choose Country</option>
                {countries?.map((country) => (
                  <option key={country?.id} value={country?.code}>
                    {country?.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
        {/* <small className="text-black text-opacity-50 my-5">
          Last Saved: 2nd Mar 2024 4:30 PM
        </small> */}
      </div>
      <div className="md:col-span-6 ">
        <FieldLabel name="Birthday" />
        <div className="mb-2">
          <div className="flex w-full max-w-md rounded-lg border border-[#ddecf3]">
            <div className="flex-1 py-2 px-4 border-r-[1px] border-[#ddecf3]">
              <label
                htmlFor="days"
                className="block text-xs text-black text-opacity-50"
              >
                Days
              </label>
              <input
                type="number"
                id="days"
                value={day}
                placeholder="DD"
                onChange={(e) => {
                  const value = e.target.value;
                  if (
                    value === "" ||
                    (Number(value) >= 1 && Number(value) <= 31)
                  ) {
                    setDay(value);
                  }
                }}
                min={1}
                max={31}
                className="block w-full border-none outline-none"
                inputMode="numeric"
              />
            </div>
            <div className="flex-1 py-2 px-4 border-r-[1px] border-[#ddecf3]">
              <label
                htmlFor="month"
                className="block text-xs text-black text-opacity-50"
              >
                Month
              </label>
              <input
                type="number"
                id="month"
                value={month}
                placeholder="MM"
                min={1}
                max={12}
                onChange={(e) => {
                  const value = e.target.value;
                  if (
                    value === "" ||
                    (Number(value) >= 1 && Number(value) <= 12)
                  ) {
                    setMonth(value);
                  }
                }}
                className="block w-full border-none outline-none"
                inputMode="numeric"
              />
            </div>
            <div className="flex-1 py-2 px-4">
              <label
                htmlFor="year"
                className="block text-xs text-black text-opacity-50"
              >
                Year
              </label>
              <input
                type="number"
                id="year"
                value={year}
                placeholder="YYYY"
                min={1960}
                max={2022}
                onChange={(e) => {
                  const value = e.target.value;
                  if (
                    value === "" ||
                    (Number(value) >= 1 && Number(value) <= 2022)
                  ) {
                    setYear(value);
                  }
                }}
                className="block w-full border-none outline-none"
                inputMode="numeric"
              />
            </div>
          </div>
        </div>

        <FieldLabel name="Details" />
        <div className="mb-2">
          <div className="rounded-lg px-4 border-[#ddecf3] border-[1px] py-2">
            <div className="w-full">
              <label
                htmlFor="phoneNumber"
                className="text-xs font-medium text-black text-opacity-50"
              >
                Gender
              </label>

              <select
                id="type"
                className="block w-full outline-none bg-transparent text-black"
                value={profile?.gender}
                onChange={(e) => handleProfileChange("gender", e.target.value)}
              >
                <option disabled selected value="0">
                  Gender
                </option>
                <option value="1">Male</option>
                <option value="2">Female</option>
              </select>
            </div>
          </div>
        </div>
        <div className="mb-2">
          <div className="rounded-lg px-4 border-[#ddecf3] border-[1px] py-2">
            <div className="w-full">
              <label
                htmlFor="phoneNumber"
                className="text-xs font-medium text-black text-opacity-50"
              >
                Occupation
              </label>
              <input
                type="text"
                id="occupation"
                value={profile?.occupation}
                className="block w-full outline-none bg-transparent text-black"
                name="occupation"
                onChange={(e) =>
                  handleProfileChange("occupation", e.target.value)
                }
              />
            </div>
          </div>
        </div>
        <FieldLabel name="Notes" />
        <div className="rounded-lg px-4 border-[#ddecf3] border-[1px] py-2">
          <div className="w-full">
            <label
              htmlFor="phoneNumber"
              className="text-xs font-medium text-black text-opacity-50"
            >
              Notes
            </label>

            <textarea
              id="notes"
              className="block w-full outline-none bg-transparent text-black"
              rows="4"
              value={profile?.notes}
              onChange={(e) => handleProfileChange("notes", e.target.value)}
            ></textarea>
          </div>
        </div>
        <FieldLabel name="Statistics" />
        <div className="mb-2">
          <div className="flex w-full max-w-md rounded-lg border border-[#ddecf3]">
            <div className="flex-1 py-2 px-4 border-r-[1px] border-[#ddecf3]">
              <label
                htmlFor="totalSpend"
                className="block text-xs text-black text-opacity-50"
              >
                Total spend
              </label>
              <input
                type="text"
                id="totalSpend"
                value={statistics?.totalSpend || 0}
                className="block w-full border-none outline-none"
                name="totalSpend"
                disabled
              />
            </div>
            <div className="flex-1 py-2 px-4 ">
              <label
                htmlFor="customerLiftime"
                className="block text-xs text-black text-opacity-50"
              >
                Customer Liftime
              </label>
              <input
                type="text"
                id="customerLiftime"
                defaultValue="0 All"
                className="block w-full border-none outline-none"
                value={statistics?.customerLiftime || 0}
                name="customerLiftime"
                disabled
              />
            </div>
          </div>
        </div>
        <div className="mb-2">
          <div className="flex w-full max-w-md rounded-lg border border-[#ddecf3]">
            <div className="flex-1 py-2 px-4 border-r-[1px] border-[#ddecf3]">
              <label
                htmlFor="totalBooked"
                className="block text-xs text-black text-opacity-50"
              >
                Completed bookings
              </label>
              <input
                type="text"
                id="totalBooked"
                className="block w-full border-none outline-none"
                value={statistics?.totalBooked || 0}
                name="totalBooked"
                disabled
              />
            </div>
            <div className="flex-1 py-2 px-4">
              <label
                htmlFor="totalMissed"
                className="block text-xs text-black text-opacity-50"
              >
                Missed
              </label>
              <input
                type="text"
                id="totalMissed"
                value={statistics?.totalMissed || 0}
                className="block w-full border-none outline-none"
                disabled
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DetailTab;
