import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { setUser } from "../../redux/actions/userActions";
import { setCountry } from "../../redux/actions/countryAction";
import { setLanguage } from "../../redux/actions/languageAction";
import { setCategory } from "../../redux/actions/categoryAction";
import ButtonLoader from "../Common/ButtonLoader";
import { fetchCountry } from "../Standards/Country";
import { fetchLanguage } from "../Standards/Language";
import { fetchCategory } from "../Standards/Category";

function Form() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [credentials, setCredentials] = useState({
    username: "",
    password: "",
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setCredentials((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    setLoading(true);
    const loginUrl = `${process.env.REACT_APP_API_URL}/api/v1/authentications/login`;

    try {
      const loginResponse = await fetch(loginUrl, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(credentials),
        credentials: 'include',
      });

      const loginData = await loginResponse.json();

      if (loginResponse.ok) {
        toast.success("Login Successfully!");
        dispatch(setUser(loginData));

        if (loginData.accessToken) {
          const countryData = await fetchCountry(loginData.accessToken);
          if (countryData) {
            dispatch(setCountry(countryData));
          }

          const languageData = await fetchLanguage(loginData.accessToken);
          if (languageData) {
            dispatch(setLanguage(languageData));
          }

          const categoryData = await fetchCategory(loginData.accessToken);
          if (categoryData) {
            dispatch(setCategory(categoryData.list));
          }
        }
        navigate("/calender");
      } else {
        toast.error(loginData?.detail || "Internal Server Error");
      }
    } catch (error) {
      toast.error(
        error.message || "Network error. Please check your connection."
      );
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-[93vh] flex items-center justify-center bg-gray-100 py-12 px-4 sm:px-6 lg:px-8 overflow-hidden">
      <div className="max-w-sm w-full space-y-6">
        <div className="flex items-center justify-center">
          <img
            src="/assets/images/logo.png"
            alt="logo"
            width={251}
            height={46}
          />
        </div>
        <div>
          <div className="text-center text-lg pt-10">Log in</div>
        </div>
        <form onSubmit={handleLogin}>
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor="username" className="sr-only">
                Email address
              </label>
              <input
                id="username"
                name="username"
                type="email"
                required
                className="appearance-none mb-5 rounded relative block w-full px-3 py-4 border border-gray-300 placeholder-gray-700 text-gray-900 focus:outline-none focus:border-primary focus:z-10 sm:text-sm"
                placeholder="Username"
                onChange={handleChange}
                value={credentials.username}
              />
            </div>
            <div>
              <label htmlFor="password" className="sr-only mt-5">
                Password
              </label>
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="current-password"
                required
                className="appearance-none mb-5 rounded relative block w-full px-3 py-4 border border-gray-300 placeholder-gray-700 text-gray-900 focus:outline-none focus:border-primary focus:z-10 sm:text-sm"
                placeholder="Password"
                onChange={handleChange}
                value={credentials.password}
              />
            </div>
          </div>
          <div>
            <button
              type="submit"
              className="group relative w-full flex justify-center py-4 px-4 border border-transparent text-sm font-medium rounded-md text-primary hover:text-secondary bg-secondary hover:bg-primary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              disabled={loading}
            >
              {loading ? <ButtonLoader /> : "Log In"}
            </button>
          </div>
          <div className="flex items-center justify-start mt-3">
            <div className="text-sm">
              <Link className="font-sm text-gray-600">
                Forgot Password? <span className="font-bold">Click Here</span>
              </Link>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default Form;
