import React from 'react';
import Topbar from '../Components/Common/Topbar';
import Form from '../Components/Login/Form';

function Login() {
  return (
    <div className="min-h-screen">
      <Topbar />
      <Form />
    </div>
  );
}

export default Login;
