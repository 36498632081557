import React from "react";
import FieldLabel from "../../Common/FieldLabel";
import { CommentsIcon } from "../../../utils/Icons";

export default function Other({ formOther, setFormOther }) {
  const handleOtherFormChange = (field, value) => {
    setFormOther((prevForm) => ({
      ...prevForm,
      [field]: value,
    }));
  };

  const labels = {
    arthritis: "Has arthritis?",
    badConditionOfGingiva: "Has bad condition of gingiva?",
    bronchialAsthma: "Has bronchial asthma?",
    chemotherapy: "Is having chemotherapy?",
    chronicBronchitis: "Has chronic bronchitis?",
    currentActiveMedication: "Is currently under active medication?",
    diabetes: "Has diabetes?",
    epilepsy: "Has epilepsy?",
    hyperThyroidism: "Has hyper thyroidism?",
    hypoThyroidism: "Has hypo thyroidism?",
    liverDiseases: "Has any liver disease?",
    mentalDisease: "Has any mental disease?",
    osteoporosis: "Has osteoporosis?",
    peridicMedication: "Takes periodic medication?",
    radiotherapy: "Is under radiotherapy?",
    regularExposureToXRays: "Is regularly under X-Ray exposure?",
    rheumaticFever: "Has rheumatic fever?",
    ssTs: "Has any SST-s?",
    stomachUlcer: "Has stomach ulcer?",
    suffersFromDisease: "Suffers from any disease?",
    systemicDisease: "Has any systemic disease?",
    tuberculosis: "Has tuberculosis?",
    underMedicalSupervision: "Is under medical supervision?",
  };

  return (
    <div>
      <FieldLabel name={"Other Condition section"} />
      {Object.keys(labels).map((field) => (
        <div
          key={field}
          className="w-full border-[#ddecf3] border rounded-lg mb-2"
        >
          <div className="">
            <div
              className={`md:flex md:items-center md:justify-between ${
                formOther[field] !== null && formOther[field] !== ""
                  ? "border-b border-[#ddecf3]"
                  : ""
              }`}
            >
              <div className="py-4 px-4 md:border-0 border-b border-[#ddecf3]">
                <h2>{labels[field]}</h2>
              </div>
              <div className="flex items-center">
                <div
                  className={`font-medium border-[#ddecf3] py-4 px-7 text-primary flex items-center justify-center ${
                    formOther[field] !== "" && formOther[field] !== null ? "border-r-[1px] bg-[#e8f2f7]" : ""
                  }`}
                >
                  <button onClick={() => handleOtherFormChange(field, " ")}>
                    Yes
                  </button>
                </div>
                <div
                  className={`font-medium py-4 px-7 flex items-center justify-center ${
                    formOther[field] === "" || formOther[field] === null
                      ? "border-r-[1px] border-[#ddecf3] bg-[#e8f2f7]"
                      : ""
                  }`}
                >
                  <button onClick={() => handleOtherFormChange(field, "")}>
                    No
                  </button>
                </div>
                {formOther[field] !== "" && formOther[field] !== null && (
                  <div className="font-medium border-[#ddecf3] py-4 px-7 flex items-center justify-center">
                    <CommentsIcon color="#1882B1" />
                  </div>
                )}
              </div>
            </div>

            {formOther[field] !== "" && formOther[field] !== null && (
              <div className="py-4 px-4">
                <textarea
                  value={formOther[field]?.trim() === "" ? "" : formOther[field]}
                  onChange={(e) =>
                    handleOtherFormChange(field, e.target.value.trimStart())
                  }
                  className="w-full border border-gray-300 rounded-md p-2"
                  placeholder={`Provide details for ${labels[
                    field
                  ].toLowerCase()}`}
                />
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}
