// utils/authUtils.js
import { setUser } from "../../redux/actions/userActions";

export const checkAuthAndRedirect = async (
  navigate,
  dispatch,
  loggedUserData
) => {
  const accessToken = loggedUserData.accessToken;
  const accessTokenExpiration = loggedUserData.accessTokenExpiration;

  const accessTokenExpired = () => {
    if (!accessTokenExpiration) return true;
    const expirationDate = new Date(accessTokenExpiration);
    const currentDate = new Date();
    return currentDate >= expirationDate;
  };

  if (!accessToken) {
    navigate("/");
  } else {
    if (accessToken && !accessTokenExpired()) {
      return accessToken;
    } else {
      //refresh token
      const url = `${process.env.REACT_APP_API_URL}/api/v1/authentications/login/refresh`;
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "text/plain",
        },
        credentials: "include",
        redirect: "follow",
      };
      try {
        const response = await fetch(url, requestOptions);
        const data = await response.json();

        if (response.ok) {
          await dispatch(setUser(data));
          return data.accessToken;
        } else {
          console.error("Error:", data);
        }
      } catch (error) {
        console.error("Catch error:", error);
      }
    }
  }
};
