import { useState } from "react";
import { ChevronLeft, ChevronRight, Plus, X } from "lucide-react";

export default function NewBooking({ isOpen, onClose }) {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    service: "",
    staff: "",
    date: "",
    time: "",
    notes: "",
    customerType: "existing",
    customerSearch: "",
    name: "",
    phone: "",
    email: "",
    address: "",
    town: "",
    country: "",
    postCode: "",
    birthDate: "",
  });
  const [notifyCustomer, setNotifyCustomer] = useState(false);

  if (!isOpen) return null;

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleContinue = () => {
    if (step < 3) setStep(step + 1);
  };

  const handleBack = () => {
    if (step > 1) setStep(step - 1);
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <div className="space-y-4">
            <select
              name="service"
              value={formData.service}
              onChange={handleInputChange}
              className="pl-5 border w-full py-4 rounded-md"
            >
              <option value="">Select service</option>
            </select>
            <select
              name="staff"
              value={formData.staff}
              onChange={handleInputChange}
              className="pl-5 border w-full py-4 rounded-md"
            >
              <option value="">Select staff</option>
            </select>
            <div className="flex space-x-2">
              <input
                type="date"
                name="date"
                value={formData.date}
                onChange={handleInputChange}
                className="pl-5 border w-1/2 py-4 rounded-md"
              />
              <input
                type="time"
                name="time"
                value={formData.time}
                onChange={handleInputChange}
                className="pl-5 border w-1/2 py-4 rounded-md"
              />
            </div>
            <textarea
              name="notes"
              value={formData.notes}
              onChange={handleInputChange}
              placeholder="Notes"
              className="pl-5 border w-full py-4 rounded-md"
            />
            <div className="relative">
              <label className="px-5 py-4 border bg-secondary rounded-md cursor-pointer inline-block w-full text-center">
                Attach File
                <input
                  type="file"
                  className="hidden"
                  onChange={(e) => (e.target.files)}
                />
              </label>
            </div>
            <div className="flex items-center justify-between px-5 border w-full py-4 rounded-md">
              <span>Inform & remind customer</span>
              <CustomSwitch
                isOn={notifyCustomer}
                onToggle={() => setNotifyCustomer(!notifyCustomer)}
              />
            </div>
            <div className="flex items-center justify-between px-5 border w-full py-4 rounded-md">
              <span>Additional Service</span>
              <Plus />
            </div>
          </div>
        );
      case 2:
        return (
          <div className="space-y-4">
            <select
              name="customerType"
              value={formData.customerType}
              onChange={handleInputChange}
              className="pl-5 border w-full py-4 rounded-md"
            >
              <option value="existing">Existing Customer</option>
              <option value="new">New Customer</option>
            </select>
            {formData.customerType === "existing" ? (
              <input
                name="customerSearch"
                value={formData.customerSearch}
                onChange={handleInputChange}
                placeholder="Name, Email or Mobile Number"
                className="pl-5 border w-full py-4 rounded-md"
              />
            ) : (
              <>
                <input
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  placeholder="Name*"
                  className="pl-5 border w-full py-4 rounded-md"
                />
                <input
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                  placeholder="Mobile Phone"
                  className="pl-5 border w-full py-4 rounded-md"
                />
                <input
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  placeholder="Email Address"
                  className="pl-5 border w-full py-4 rounded-md"
                />
                <input
                  name="address"
                  value={formData.address}
                  onChange={handleInputChange}
                  placeholder="Address"
                  className="pl-5 border w-full py-4 rounded-md"
                />
                <input
                  name="town"
                  value={formData.town}
                  onChange={handleInputChange}
                  placeholder="Town"
                  className="pl-5 border w-full py-4 rounded-md"
                />
                <div className="flex space-x-2">
                  <input
                    name="country"
                    value={formData.country}
                    onChange={handleInputChange}
                    placeholder="Country"
                    className="pl-5 border w-full py-4 rounded-md"
                  />
                  <input
                    name="postCode"
                    value={formData.postCode}
                    onChange={handleInputChange}
                    placeholder="Post Code"
                    className="pl-5 border w-full py-4 rounded-md"
                  />
                </div>
                <div className="flex space-x-2">
                  <input
                    name="birthDate"
                    value={formData.birthDate}
                    onChange={handleInputChange}
                    placeholder="Birth Date"
                    className="pl-5 border w-full py-4 rounded-md"
                  />
                </div>
              </>
            )}
          </div>
        );
      case 3:
        return (
          <div className="space-y-4">
            <h3 className="font-bold">Booking summary</h3>
            <div className="border rounded">
              <div className=" p-4 rounded">
                <div className="flex justify-between">
                  <h3 className="font-bold">Filling</h3>
                  <p>{formData.service}</p>
                </div>
                <div className="flex justify-between">
                  <p>{formData.name || "Customer Name"}</p>
                  <p>
                    {formData.date} <br /> {formData.time}
                  </p>
                </div>
              </div>
              <div className="bg-secondary flex justify-between p-3 rounded ">
                <p className="font-bold">Total: </p>
                <p className="font-bold">XXX </p>
              </div>
            </div>
            <p className="text-sm text-gray-600 pt-36">
              SMS Reminder will be sent 24 hours before booking to{" "}
              {formData.phone}
            </p>
          </div>
        );
      default:
        return renderStep(1);
    }
  };

  return (
    <div className="fixed z-50 inset-0 bg-black bg-opacity-50 flex items-center justify-end">
      <div className="bg-white rounded-lg p-6 w-full max-w-md h-screen overflow-y-auto shadow-lg">
        <div className="flex justify-between items-center mb-4">
          <button onClick={handleBack} className="text-gray-600">
            {step > 1 && <ChevronLeft />}
          </button>
          <h2 className="text-xl text-primary">New Booking</h2>
          <button onClick={onClose} className="text-gray-600">
            <X />
          </button>
        </div>
        <hr className="pb-2" />
        <div className="relative w-full mb-12">
          <div className="absolute flex justify-between w-full -top-6">
            {["Details", "Customer", "Confirm"].map((label, index) => (
              <div
                key={label}
                className={`text-center ${
                  step === index + 1 ? "text-black " : "text-gray-600"
                }`}
                style={{ width: "33.33%" }}
              >
                <div className=" font-semibold">{label}</div>
                <div className="text-xs">Step {index + 1}</div>
              </div>
            ))}
          </div>

          <div className="relative w-full h-[2px] bg-gray-200 rounded-full mt-8 top-7">
            <div
              className="absolute top-0 left-0 h-[2px] rounded-full bg-primary transition-width duration-300"
              style={{ width: `${(step / 3) * 100}%` }}
            />
          </div>
        </div>

        {renderStep()}
        <div className="mt-6 border w-full py-4 bg-primary text-white border-none hover:bg-[#ddecf3] hover:text-primary rounded-md">
          <button
            className="flex items-center justify-between px-4  w-full"
            onClick={step === 3 ? onClose : handleContinue}
          >
            <div>{step === 3 ? "Save Booking" : "Continue"}</div>
            {step < 3 && <ChevronRight className="ml-2" />}
          </button>
        </div>
      </div>
    </div>
  );
}
function CustomSwitch({ isOn, onToggle }) {
  return (
    <div
      onClick={onToggle}
      className={`w-10 h-6 flex items-center bg-gray-200 rounded-full p-1 cursor-pointer ${
        isOn ? "bg-primary" : "bg-secondary"
      }`}
    >
      <div
        className={`bg-white w-4 h-4 rounded-full shadow-md transform duration-300 ${
          isOn ? "translate-x-4" : ""
        }`}
      />
    </div>
  );
}
