import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./Pages/Login";
import Error from "./Pages/Error";
import Calender from "./Pages/Calender";
import Booking from "./Pages/Booking";
import Customer from "./Pages/Customer";
import Staff from "./Pages/Staff";
import Service from "./Pages/Service";
import Setting from "./Pages/Setting";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AuthRedirect from "./utils/AuthRedirect";
import IsAuthenticated from "./utils/IsAuthenticated";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<IsAuthenticated />}>
          <Route index element={<Login />} />
        </Route>
        <Route path="/" element={<AuthRedirect />}>
          <Route path="calender" element={<Calender />} />
          <Route path="booking" element={<Booking />} />
          <Route path="customer" element={<Customer />} />
          <Route path="staff" element={<Staff />} />
          <Route path="service" element={<Service />} />
          <Route path="myprofile" element={<Setting />} />
        </Route>

        <Route path="*" element={<Error />} />
      </Routes>
      <ToastContainer theme="dark" />
    </Router>
  );
}

export default App;
