import React from "react";
import { ArrowLeft } from "lucide-react";
import ChangePassword from "./ChangePassword";
import ContactDetail from "./ContactDetail";

function MyProfile() {
  return (
    <div className="bg-gray-100 min-h-screen">
      <div className="w-full max-w-4xl mx-auto py-16">
        <div className="max-w-3xl mx-5 md:mx-auto space-y-6">
          <div className="flex items-center space-x-2 mb-4">
            <div className="bg-secondary text-primary p-2 rounded-lg">
              <ArrowLeft />
            </div>
            <h1 className="text-xl font-semibold">Your Personal Account</h1>
          </div>
          <ChangePassword />
          <ContactDetail />
        </div>
      </div>
    </div>
  );
}

export default MyProfile;
