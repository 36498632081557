import React from "react";
import FieldLabel from "../../Common/FieldLabel";
import { CommentsIcon } from "../../../utils/Icons";

export default function HeartOrBlood({ heartOrBloodIssues, setHeartOrBloodIssues }) {
  const handleHeartOrBloodChange = (field, value) => {
    setHeartOrBloodIssues((prevIssues) => ({
      ...prevIssues,
      [field]: value,
    }));
  };

  const labels = {
    bloodDisease: "Has any blood disease?",
    bloodTransfusion: "Is having blood transfusion?",
    congenitalHeartDisease: "Has any congenital heart disease?",
    coronaryInsufficiency: "Is having any coronary insufficiency?",
    heartSurgeryIntervention: "Has had any heart surgery intervention?",
    majorHaemorrhageAfterAnyIntervention: "Has had any major hemorrhage after any intervention?",
    highBlodPressure: "Has high blood pressure?",
    lowBloodPressure: "Has low blood pressure?",
    myocardialInfarction: "Has myocardial infarction?",
    peacemakerIntoHeart: "Has any pacemaker in the heart?",
  };

  return (
    <div>
      <FieldLabel name={"Heart or Blood Section"} />
      {Object.keys(labels).map((field) => (
        <div
          key={field}
          className="w-full border-[#ddecf3] border rounded-lg mb-2"
        >
          <div className="">
            <div
              className={`md:flex md:items-center md:justify-between ${
                heartOrBloodIssues[field] !== null && heartOrBloodIssues[field] !== ""
                  ? "border-b border-[#ddecf3]"
                  : ""
              }`}
            >
              <div className="py-4 px-4 md:border-0 border-b border-[#ddecf3]">
                <h2>{labels[field]}</h2>
              </div>
              <div className="flex items-center">
                <div
                  className={`font-medium border-[#ddecf3] py-4 px-7 text-primary flex items-center justify-center ${
                    heartOrBloodIssues[field] !== "" && heartOrBloodIssues[field] !== null ? "border-r-[1px] bg-[#e8f2f7]" : ""
                  }`}
                >
                  <button onClick={() => handleHeartOrBloodChange(field, " ")}>
                    Yes
                  </button>
                </div>
                <div
                  className={`font-medium py-4 px-7 flex items-center justify-center ${
                    heartOrBloodIssues[field] === "" || heartOrBloodIssues[field] === null
                      ? "border-r-[1px] border-[#ddecf3] bg-[#e8f2f7]"
                      : ""
                  }`}
                >
                  <button onClick={() => handleHeartOrBloodChange(field, "")}>
                    No
                  </button>
                </div>
                {heartOrBloodIssues[field] !== "" && heartOrBloodIssues[field] !== null && (
                  <div className="font-medium border-[#ddecf3] py-4 px-7 flex items-center justify-center">
                    <CommentsIcon color="#1882B1" />
                  </div>
                )}
              </div>
            </div>

            {heartOrBloodIssues[field] !== "" && heartOrBloodIssues[field] !== null && (
              <div className="py-4 px-4">
                <textarea
                  value={heartOrBloodIssues[field]?.trim() === "" ? "" : heartOrBloodIssues[field]}
                  onChange={(e) =>
                    handleHeartOrBloodChange(field, e.target.value.trimStart())
                  }
                  className="w-full border border-gray-300 rounded-md p-2"
                  placeholder={`Provide details for ${labels[
                    field
                  ].toLowerCase()}`}
                />
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}
