import React from "react";
import FieldLabel from "../Common/FieldLabel";
import { useSelector } from "react-redux";
import AssignedStaff from "./AssignedStaff";

function DetailTab({ serviceRequest, setServiceRequest }) {
  const duration = serviceRequest?.duration || "00:30:00"; 
  const [hours, minutes] = duration.split(":").map((part) => parseInt(part, 10));

  const handleServiceChange = (field, value) => {
    setServiceRequest((prevServiceRequest) => ({
      ...prevServiceRequest,
      [field]: value,
    }));
  };

  const handleDurationChange = () => {
    const hoursValue = document.getElementById("hours").value || "";  
    const minutesValue = document.getElementById("minutes").value || ""; 

    const formattedHours = hoursValue ? String(hoursValue).padStart(2, "0") : ""; 
    const formattedMinutes = minutesValue ? String(minutesValue).padStart(2, "0") : "";  

    const duration = `${formattedHours}:${formattedMinutes}:00`;

    setServiceRequest((prevServiceRequest) => ({
      ...prevServiceRequest,
      duration,
    }));
  };

  const category = useSelector((state) => state.categoryData.category);

  return (
    <div className="pb-10 px-5 grid grid-cols-1 md:grid-cols-12 gap-5">
      <div className="md:col-span-6">
        <FieldLabel name="Service Details" />
        <div className="mb-2">
          <div className="rounded-lg px-4 border-[#ddecf3] border-[1px] py-2">
            <div className="w-full">
              <label htmlFor="name" className="text-xs font-medium text-black">
                Name
              </label>
              <input
                type="text"
                id="name"
                placeholder="New Service"
                value={serviceRequest?.name}
                className="block w-full outline-none bg-transparent text-black"
                onChange={(e) => handleServiceChange("name", e.target.value)}
              />
            </div>
          </div>
        </div>
        <div className="relative z-0 w-full group mb-2">
          <textarea
            id="description"
            className="ps-4 block py-4 px-0 w-full text-sm text-opacity-50 bg-transparent border border-[#ddecf3] appearance-none rounded-lg focus:outline-none focus:ring-0"
            placeholder="Description"
            value={serviceRequest?.description}
            onChange={(e) => handleServiceChange("description", e.target.value)}
            rows="4"
          ></textarea>
        </div>
        <div className="mb-2">
          <div className="flex w-full max-w-md rounded-lg border border-[#ddecf3]">
            <div className="flex-1 py-2 px-4 border-r-[1px] border-[#ddecf3]">
              <label
                htmlFor="hours"
                className="block text-xs text-black text-opacity-50"
              >
                Hours
              </label>
              <input
                type="number"
                id="hours"
                className="block w-full border-none outline-none"
                value={hours} 
                min={0}
                max={23}
                onChange={handleDurationChange}
              />
            </div>
            <div className="flex-1 py-2 px-4">
              <label
                htmlFor="minutes"
                className="block text-xs text-black text-opacity-50"
              >
                Minutes
              </label>
              <input
                type="number"
                id="minutes"
                className="block w-full border-none outline-none"
                value={minutes === 0 ? "" : minutes}
                min={0}
                max={59}
                onChange={handleDurationChange}
              />
            </div>
          </div>
        </div>
        <div className="flex items-center gap-2 mb-2">
          <div className="flex-1 py-2 px-4 border-[1px] rounded-lg border-[#ddecf3]">
            <label
              htmlFor="price"
              className="block text-xs text-black text-opacity-50"
            >
              Price
            </label>
            <input
              type="text"
              id="price"
              value={serviceRequest?.price}
              className="block w-full border-none outline-none"
              onChange={(e) => handleServiceChange("price", e.target.value)}
            />
          </div>
        </div>
        <div className="my-4 md:my-4">
          <div className="flex items-center gap-3">
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                className="sr-only peer"
                checked={serviceRequest.bookOnline}
                onChange={(e) =>
                  handleServiceChange("bookOnline", e.target.checked)
                }
              />
              <div className="group peer bg-white rounded-full duration-300 w-12 h-6 ring-2 ring-[#ddecf3] after:duration-300 after:bg-[#ddecf3] peer-checked:after:bg-primary peer-checked:ring-primary after:rounded-full after:absolute after:h-5 after:w-5 after:top-0.5 after:left-0.5 after:flex after:justify-center after:items-center peer-checked:after:translate-x-6 peer-hover:after:scale-95"></div>
            </label>
            <h2 className="md:text-md text-sm">Book Online</h2>
          </div>
        </div>
        <FieldLabel name="Service Category" />
        <div className="md:mb-7 mb-2">
          <div className="flex-1 py-2 px-4 border-[1px] rounded-lg border-[#ddecf3]">
            <label
              htmlFor="categoryId"
              className="block text-xs text-black text-opacity-50 ps-1"
            >
              Category
            </label>
            <select
              id="categoryId"
              className="outline-none w-full"
              onChange={(e) => handleServiceChange("categoryId", e.target.value)}
              value={serviceRequest?.categoryId}
            >
              <option selected disabled value="">
                Choose Category
              </option>
              {category?.map((item) => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <AssignedStaff
        serviceRequest={serviceRequest}
        setServiceRequest={setServiceRequest}
        onChange={handleServiceChange}
      />
    </div>
  );
}

export default DetailTab;
