import React from "react";
import Layout from "../Components/Common/Layout";
import NewBooking from "../Components/Booking/NewBooking";

function Booking() {
  return (
    <Layout>
      <NewBooking />
    </Layout>
  );
}

export default Booking;
