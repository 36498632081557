import React, { useState } from "react";
import { Plus } from "lucide-react";
import ServiceDetails from "./ServiceDetails";
import { UserIcon } from "../../utils/Icons";
import TopActions from "./TopActions";
import LeftSide from "./LeftSide";

function ServiceList() {
  const [services, setServices] = useState([]);
  const [selectedServiceId, setSelectedServiceId] = useState(null);
  const [activeView, setActiveView] = useState("details");
  const [createService, setCreateService] = useState(false);

  const [serviceRequest, setServiceRequest] = useState({
    name: "",
    description: "",
    duration: "00:00:00",
    price: "",
    bookOnline: false,
    categoryId: "",
    assignedStaff: [],
  });

  const handleOnBack = () => {
    setSelectedServiceId(null);
    setCreateService(false);
  };

  const handleOnCreateServiceClick = () => {
    setCreateService(true);
    setSelectedServiceId(null);
    setServiceRequest({
      name: "",
      description: "",
      duration: "",
      price: "",
      bookOnline: "",
      categoryId: "",
      assignedStaff: [],
    });
  };

  return (
    <>
      <div className="w-full max-w-6xl mx-auto">
        <TopActions
          services={services}
          setServices={setServices}
          createService={createService}
          setCreateService={setCreateService}
          selectedServiceId={selectedServiceId}
          setSelectedServiceId={setSelectedServiceId}
          serviceRequest={serviceRequest}
          setServiceRequest={setServiceRequest}
          setActiveView={setActiveView}
        />
        <div className="bg-white rounded-xl border-[1px] border-[#ddecf3] shadow-lg md:h-[75vh] h-[70vh] flex flex-col">
          <div className="flex flex-col lg:flex-row h-full">
            <LeftSide
              services={services}
              selectedServiceId={selectedServiceId}
              setSelectedServiceId={setSelectedServiceId}
              setServices={setServices}
              setCreateService={setCreateService}
              createService={createService}
            />
            <div className="w-full overflow-y-auto scroll-smooth rounded-r-xl">
              {createService || selectedServiceId ? (
                <ServiceDetails
                  selectedService={createService ? null : selectedServiceId}
                  activeView={activeView}
                  setActiveView={setActiveView}
                  onBack={handleOnBack}
                  serviceRequest={serviceRequest}
                  setServiceRequest={setServiceRequest}
                />
              ) : (
                <div className="hidden md:px-0 px-4 h-full w-full lg:w-2/3-50 rounded-r-xl md:flex justify-center items-center">
                  <div className="flex flex-col items-center text-center mb-6">
                    <UserIcon className="h-12 w-12 text-primary " />
                    <h2 className="md:text-2xl text-xl font-semibold text-primary my-3">
                      Services
                    </h2>
                    <p className="text-sm text-gray-600 md:w-2/3">
                      On the left you can see the list of services your business
                      offers and on the right further details about each service
                      are presented.
                    </p>
                    <div className="md:flex md:justify-center gap-5 mt-7">
                      <button
                        onClick={handleOnCreateServiceClick}
                        className="font-medium mb-3 bg-[#ddecf3] hover:text-white text-primary hover:bg-primary flex items-center justify-center w-full md:w-auto px-6  py-3 rounded-lg"
                      >
                        <Plus size={22} className="me-3" /> New Service
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ServiceList;
