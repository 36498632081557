import React from "react";

function Tabs({
  selectedService,
  activeView,
  setActiveView,
  setIsDeleteDialogOpen,
}) {
  return (
    <div className="bg-[#ddecf3] w-full border-b border-[#ddecf3] md:rounded-tr-xl sticky top-0 z-20">
      <div className="px-5 py-3 grid grid-cols-3 gap-2 md:flex md:items-center md:space-x-6">
        <button
          className={`text-md rounded-lg  ${
            activeView === "details"
              ? "text-primary font-bold"
              : "text-gray-600"
          }`}
          onClick={() => setActiveView("details")}
        >
          Service
        </button>
        {/* {selectedService && (
          <button
            className={`text-md rounded-lg ${
              activeView === "settings"
                ? "text-primary font-bold"
                : "text-gray-600"
            }`}
            onClick={() => setActiveView("settings")}
          >
            Settings
          </button>
        )} */}

        {selectedService && (
          <button
            className={`text-md rounded-lg ${
              activeView === "delete"
                ? "text-primary font-bold"
                : "text-gray-600"
            }`}
            onClick={() => setIsDeleteDialogOpen(true)}
          >
            Delete
          </button>
        )}
      </div>
    </div>
  );
}

export default Tabs;
