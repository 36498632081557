import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import FieldLabel from "../Common/FieldLabel";
import { checkAuthAndRedirect } from "../Common/RefreshToken";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AssignedServices from "./AssignedServices";
import ButtonLoader from "../Common/ButtonLoader";
import PhoneNumberInput from "./PhoneNumberInput"; // Import PhoneNumberInput

export default function StaffTab({
  userCreateRequest,
  setUserCreateRequest,
  staffProfileCreateRequest,
  setStaffProfileCreateRequest,
  selectedStaff,
}) {
  const [loading, setLoading] = useState(false);
  const [services, setServices] = useState([]);
  const loggedUserData = useSelector((state) => state.userData.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleUserCreateRequestChange = (field, value) => {
    setUserCreateRequest((prevUserCreateRequest) => ({
      ...prevUserCreateRequest,
      [field]: value,
    }));
  };

  const fetchServices = async () => {
    setLoading(true);
    const token = await checkAuthAndRedirect(
      navigate,
      dispatch,
      loggedUserData
    );
    const url = `${process.env.REACT_APP_API_URL}/api/v1/services/grouped`;
    const options = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const response = await fetch(url, options);
      const data = await response.json();

      if (response.ok) {
        setServices(data.serviceCategories || []);
      } else {
        toast.error(data.detail || "Failed to fetch services.");
      }
    } catch (error) {
      toast.error("Error fetching services.");
    } finally {
      setLoading(false);
    }
  };

  const countries = useSelector((state) => state.countryData.country);

  useEffect(() => {
    fetchServices();
    //eslint-disable-next-line
  }, [selectedStaff]);

  return (
    <div className="pb-5 px-5 grid grid-cols-1 md:grid-cols-12 md:gap-5">
      <div className="md:col-span-6">
        <FieldLabel name="Staff Details" />
        <div className="mb-2">
          <div className="flex w-full max-w-md rounded-lg border border-[#ddecf3]">
            <div className="flex-1 py-2 px-4 border-r-[1px] border-[#ddecf3]">
              <label
                htmlFor="first_name"
                className="block text-xs text-black text-opacity-50"
              >
                First Name
              </label>
              <input
                type="text"
                id="first_name"
                value={userCreateRequest?.firstName}
                onChange={(e) =>
                  handleUserCreateRequestChange("firstName", e.target.value)
                }
                className="block w-full border-none outline-none"
              />
            </div>
            <div className="flex-1 py-2 px-4 ">
              <label
                htmlFor="last_name"
                className="block text-xs text-black text-opacity-50"
              >
                Last Name
              </label>
              <input
                type="text"
                id="last_name"
                value={userCreateRequest?.lastName}
                onChange={(e) =>
                  handleUserCreateRequestChange("lastName", e.target.value)
                }
                className="block w-full border-none outline-none"
              />
            </div>
          </div>
        </div>
        <div className="mb-2">
          <div className="rounded-lg px-4 border-[#ddecf3] border-[1px] py-2">
            <div className="w-full">
              <label
                htmlFor="name"
                className="text-xs font-medium text-black text-opacity-50 "
              >
                Email Address
              </label>

              <input
                type="email"
                id="name"
                className="block w-full outline-none bg-transparent text-black"
                value={userCreateRequest?.email}
                onChange={(e) =>
                  handleUserCreateRequestChange("email", e.target.value)
                }
              />
            </div>
          </div>
        </div>

        {/* Use the PhoneNumberInput component */}
        <PhoneNumberInput
          userCreateRequest={userCreateRequest}
          setUserCreateRequest={setUserCreateRequest}
          countries={countries}
        />
      </div>
      {loading ? (
        <div className="flex ml-40 items-center">
          <ButtonLoader />
        </div>
      ) : (
        <AssignedServices
          services={services}
          staffProfileCreateRequest={staffProfileCreateRequest}
          setStaffProfileCreateRequest={setStaffProfileCreateRequest}
        />
      )}
    </div>
  );
}
